/* ----- AUTH ----- */
export const ON_LOAD_LOGIN_REQUESTED = 'ON_LOAD_LOGIN_REQUESTED';
export const ON_LOAD_LOGIN_SUCCESS = 'ON_LOAD_LOGIN_SUCCESS';
export const ON_LOAD_LOGIN_FAILED = 'ON_LOAD_LOGIN_FAILED';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const POST_RESET_PASSWORD_REQUESTED = 'POST_RESET_PASSWORD_REQUESTED';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export const POST_RESET_PASSWORD_FAILED = 'POST_RESET_PASSWORD_FAILED';

/* ----- CELL MEMBERS ----- */
export const GET_CELL_MEMBERS_REQUESTED = 'GET_CELL_MEMBERS_REQUESTED';
export const GET_CELL_MEMBERS_SUCCESS = 'GET_CELL_MEMBERS_SUCCESS';
export const GET_CELL_MEMBERS_FAILED = 'GET_CELL_MEMBERS_FAILED';
export const GET_CELL_MEMBER_BY_ID_REQUESTED = 'GET_CELL_MEMBER_BY_ID_REQUESTED';
export const GET_CELL_MEMBER_BY_ID_SUCCESS = 'GET_CELL_MEMBER_BY_ID_SUCCESS';
export const GET_CELL_MEMBER_BY_ID_FAILED = 'GET_CELL_MEMBER_BY_ID_FAILED';
export const ADD_CELL_MEMBER_REQUESTED = 'ADD_CELL_MEMBER_REQUESTED';
export const ADD_CELL_MEMBER_SUCCESS = 'ADD_CELL_MEMBER_SUCCESS';
export const ADD_CELL_MEMBER_FAILED = 'ADD_CELL_MEMBER_FAILED';
export const UPDATE_CELL_MEMBER_REQUESTED = 'UPDATE_CELL_MEMBER_REQUESTED';
export const UPDATE_CELL_MEMBER_SUCCESS = 'UPDATE_CELL_MEMBER_SUCCESS';
export const UPDATE_CELL_MEMBER_FAILED = 'UPDATE_CELL_MEMBER_FAILED';
export const DELETE_CELL_MEMBER_REQUESTED = 'DELETE_CELL_MEMBER_REQUESTED';
export const DELETE_CELL_MEMBER_SUCCESS = 'DELETE_CELL_MEMBER_SUCCESS';
export const DELETE_CELL_MEMBER_FAILED = 'DELETE_CELL_MEMBER_FAILED';
export const CLEAR_CELL_MEMBERS = 'CLEAR_CELL_MEMBERS';

/* ----- CELLS ----- */
export const GET_CELLS_REQUESTED = 'GET_CELLS_REQUESTED';
export const GET_CELLS_SUCCESS = 'GET_CELLS_SUCCESS';
export const GET_CELLS_FAILED = 'GET_CELLS_FAILED';
export const GET_CELL_BY_ID_REQUESTED = 'GET_CELL_BY_ID_REQUESTED';
export const GET_CELL_BY_ID_SUCCESS = 'GET_CELL_BY_ID_SUCCESS';
export const GET_CELL_BY_ID_FAILED = 'GET_CELL_BY_ID_FAILED';
export const ADD_CELL_REQUESTED = 'ADD_CELL_REQUESTED';
export const ADD_CELL_SUCCESS = 'ADD_CELL_SUCCESS';
export const ADD_CELL_FAILED = 'ADD_CELL_FAILED';
export const UPDATE_CELL_REQUESTED = 'UPDATE_CELL_REQUESTED';
export const UPDATE_CELL_SUCCESS = 'UPDATE_CELL_SUCCESS';
export const UPDATE_CELL_FAILED = 'UPDATE_CELL_FAILED';
export const DELETE_CELL_REQUESTED = 'DELETE_CELL_REQUESTED';
export const DELETE_CELL_SUCCESS = 'DELETE_CELL_SUCCESS';
export const DELETE_CELL_FAILED = 'DELETE_CELL_FAILED';
export const CLEAR_CELLS = 'CLEAR_CELLS';

/* ----- GUESTS ----- */
export const GET_GUESTS_REQUESTED = 'GET_GUESTS_REQUESTED';
export const GET_GUESTS_SUCCESS = 'GET_GUESTS_SUCCESS';
export const GET_GUESTS_FAILED = 'GET_GUESTS_FAILED';
export const GET_GUEST_BY_ID_REQUESTED = 'GET_GUEST_BY_ID_REQUESTED';
export const GET_GUEST_BY_ID_SUCCESS = 'GET_GUEST_BY_ID_SUCCESS';
export const GET_GUEST_BY_ID_FAILED = 'GET_GUEST_BY_ID_FAILED';
export const ADD_GUEST_REQUESTED = 'ADD_GUEST_REQUESTED';
export const ADD_GUEST_SUCCESS = 'ADD_GUEST_SUCCESS';
export const ADD_GUEST_FAILED = 'ADD_GUEST_FAILED';
export const UPDATE_GUEST_REQUESTED = 'UPDATE_GUEST_REQUESTED';
export const UPDATE_GUEST_SUCCESS = 'UPDATE_GUEST_SUCCESS';
export const UPDATE_GUEST_FAILED = 'UPDATE_GUEST_FAILED';
export const DELETE_GUEST_REQUESTED = 'DELETE_GUEST_REQUESTED';
export const DELETE_GUEST_SUCCESS = 'DELETE_GUEST_SUCCESS';
export const DELETE_GUEST_FAILED = 'DELETE_GUEST_FAILED';
export const CLEAR_GUESTS = 'CLEAR_GUESTS';

/* ----- CELL LEADERS ----- */
export const GET_CELL_LEADERS_REQUESTED = 'GET_CELL_LEADERS_REQUESTED';
export const GET_CELL_LEADERS_SUCCESS = 'GET_CELL_LEADERS_SUCCESS';
export const GET_CELL_LEADERS_FAILED = 'GET_CELL_LEADERS_FAILED';
export const GET_CELL_LEADER_BY_ID_REQUESTED = 'GET_CELL_LEADER_BY_ID_REQUESTED';
export const GET_CELL_LEADER_BY_ID_SUCCESS = 'GET_CELL_LEADER_BY_ID_SUCCESS';
export const GET_CELL_LEADER_BY_ID_FAILED = 'GET_CELL_LEADER_BY_ID_FAILED';
export const ADD_CELL_LEADER_REQUESTED = 'ADD_CELL_LEADER_REQUESTED';
export const ADD_CELL_LEADER_SUCCESS = 'ADD_CELL_LEADER_SUCCESS';
export const ADD_CELL_LEADER_FAILED = 'ADD_CELL_LEADER_FAILED';
export const UPDATE_CELL_LEADER_REQUESTED = 'UPDATE_CELL_LEADER_REQUESTED';
export const UPDATE_CELL_LEADER_SUCCESS = 'UPDATE_CELL_LEADER_SUCCESS';
export const UPDATE_CELL_LEADER_FAILED = 'UPDATE_CELL_LEADER_FAILED';
export const DELETE_CELL_LEADER_REQUESTED = 'DELETE_CELL_LEADER_REQUESTED';
export const DELETE_CELL_LEADER_SUCCESS = 'DELETE_CELL_LEADER_SUCCESS';
export const DELETE_CELL_LEADER_FAILED = 'DELETE_CELL_LEADER_FAILED';
export const CLEAR_CELL_LEADERS = 'CLEAR_CELL_LEADERS';

/* ----- REPORTS ----- */
export const GET_REPORTS_REQUESTED = 'GET_REPORTS_REQUESTED';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILED = 'GET_REPORTS_FAILED';
export const GET_REPORT_BY_ID_REQUESTED = 'GET_REPORT_BY_ID_REQUESTED';
export const GET_REPORT_BY_ID_SUCCESS = 'GET_REPORT_BY_ID_SUCCESS';
export const GET_REPORT_BY_ID_FAILED = 'GET_REPORT_BY_ID_FAILED';
export const ADD_REPORT_REQUESTED = 'ADD_REPORT_REQUESTED';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const ADD_REPORT_FAILED = 'ADD_REPORT_FAILED';
export const UPDATE_REPORT_REQUESTED = 'UPDATE_REPORT_REQUESTED';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAILED = 'UPDATE_REPORT_FAILED';
export const DELETE_REPORT_REQUESTED = 'DELETE_REPORT_REQUESTED';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILED = 'DELETE_REPORT_FAILED';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const SUBMIT_NEW_REPORT_REQUESTED = 'SUBMIT_NEW_REPORT_REQUESTED';
export const SUBMIT_NEW_REPORT_SUCCESS = 'SUBMIT_NEW_REPORT_SUCCESS';
export const SUBMIT_NEW_REPORT_FAILED = 'SUBMIT_NEW_REPORT_FAILED';

/** ----- UPDATE ATTENDANCE ----- */
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const UPDATE_GUEST_ATTENDANCE = 'UPDATE_GUEST_ATTENDANCE';
export const UPDATE_TESTIMONIES = 'UPDATE_TESTIMONIES';
export const UPDATE_EVALUATIONS = 'UPDATE_EVALUATIONS';
export const UPDATE_DATE = 'UPDATE_DATE';


/* ----- REPORT DETAILS ----- */
export const GET_REPORT_DETAILS_REQUESTED = 'GET_REPORT_DETAILS_REQUESTED';
export const GET_REPORT_DETAILS_SUCCESS = 'GET_REPORT_DETAILS_SUCCESS';
export const GET_REPORT_DETAILS_FAILED = 'GET_REPORT_DETAILS_FAILED';


/* ----- ADMIN REPORT ----- */
export const GET_ADMIN_REPORT_REQUESTED = 'GET_ADMIN_REPORT_REQUESTED';
export const GET_ADMIN_REPORT_SUCCESS = 'GET_ADMIN_REPORT_SUCCESS';
export const GET_ADMIN_REPORT_FAILED = 'GET_ADMIN_REPORT_FAILED';

/* ----- USERS ----- */
export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

/* ----- USER ----- */
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const DELETE_USER_REQUESTED = 'DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const UPDATE_PASSWORD_REQUESTED = 'UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';

