import {
    GET_CELL_LEADERS_REQUESTED,
    GET_CELL_LEADERS_SUCCESS,
    GET_CELL_LEADERS_FAILED,
    GET_CELL_LEADER_BY_ID_REQUESTED,
    GET_CELL_LEADER_BY_ID_SUCCESS,
    GET_CELL_LEADER_BY_ID_FAILED,
    ADD_CELL_LEADER_REQUESTED,
    ADD_CELL_LEADER_SUCCESS,
    ADD_CELL_LEADER_FAILED,
    UPDATE_CELL_LEADER_REQUESTED,
    UPDATE_CELL_LEADER_SUCCESS,
    UPDATE_CELL_LEADER_FAILED,
    DELETE_CELL_LEADER_REQUESTED,
    DELETE_CELL_LEADER_SUCCESS,
    DELETE_CELL_LEADER_FAILED,
} from '../actions/types';

const initialState = {
    cellLeaders: [],
    cellLeader: null,
    error: null,
    loading: false,
    success: false,
    cellLeaderGetRequested: false,
    cellLeaderGetError: '',
    cellLeaderGetSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CELL_LEADERS_REQUESTED:
            return {
                ...state,
                loading: true,
                cellLeaderGetRequested: true,
            };
        case GET_CELL_LEADERS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                cellLeaderGetRequested: false,
                cellLeaders: action.payload,
            };
        case GET_CELL_LEADERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_CELL_LEADER_BY_ID_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GET_CELL_LEADER_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                cellLeader: action.payload,
            };
        case GET_CELL_LEADER_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case ADD_CELL_LEADER_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case ADD_CELL_LEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                cellLeader: action.payload,
                cellLeaders: [...state.cellLeaders, action.payload],
            };
        case ADD_CELL_LEADER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_CELL_LEADER_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CELL_LEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                cellLeader: action.payload,
                cellLeaders: state.cellLeaders.map((cellLeader) => {
                    if (cellLeader.id === action.payload.id) {
                        return action.payload;
                    }
                    return cellLeader;
                }),
            };
        case UPDATE_CELL_LEADER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_CELL_LEADER_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case DELETE_CELL_LEADER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                cellLeaders: state.cellLeaders.filter((cellLeader) => cellLeader.id !== action.payload),
            };
        case DELETE_CELL_LEADER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}


