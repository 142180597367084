import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '../../components/Table';
import {
    getReportByIdRequested,
    getReportsRequested,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
    updateReportRequested,
} from "../../actions/reportAction";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';


const EditReport = ({
    user,
    report,
    cellMembers,
    guests,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
    reportGetRequested,
    getReportsRequested: getReport,
    updateReportRequested: updateReport,
    getReportByIdRequested: getReportById,
}) => {

    const [reportId, setReportId] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [formIsValid, setFormIsValid] = React.useState(false);
    const [date, setDate] = React.useState("");
    const navigate = useNavigate();
    const { id, cellId } = useParams();

    React.useEffect(() => {
        const isValid = handleValidaton();
        setFormIsValid(isValid);
    }, [report]);

    React.useEffect(() => {
        if (!reportGetRequested) {
            getReportById({ cellId, id });
        }
    }, [reportGetRequested]);

    const handleClAttnOnchange = (id, value) => {
        updateAttendance({ id, value });
    }

    const handleGsAttnOnchange = (id, value) => {
        updateGuestAttendance({ id, value });
    }


    const handleTmChange = (e) => {
        updateTestimonies(e.target.value);
    }

    const handleEvChange = (e) => {
        updateEvaluations(e.target.value);
    }

    const handleDateChange = (e) => {
        updateDate(e.target.value);
    }

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Save report");
        console.log(cellMembers);
        console.log(guests);
        // console.log(testimonies);
        // console.log(evaluations);
        console.log(report.date);
        updateReport({
            id: report.id,
            cell_id: cellId,
            cellMemberAttendance: cellMembers,
            guestAttendance: guests,
            evaluations: report.evaluations,
            testimonies: report.testimonies,
            date: report.date,
        })
        notify("Report updated successfully!");
        getReport(cellId);
        navigate('/dashboard/reports');

    }

    const handleValidaton = () => {
        let errors = {};
        let formIsValid = true;
        if (report != null && (report.date == null || report.date === "")) {
            formIsValid = false;
            errors["date"] = "Date cannot be empty";
        }

        setErrors(errors);
        return formIsValid;
    }

    const columns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: 'cellMember.name',
        },
        {
            Header: "Attended?",
            accessor: 'attendance',
            idAccessor: 'member_id',
            Cell: ({ value, column, row }) => (
                <div className="flex items-left">
                    <div className="ml-4">
                        <input type="checkbox"
                            className="border-solid w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value={value} checked={row.values.attendance || false} onChange={e => { handleClAttnOnchange(row.original[column.idAccessor], e.target.checked) }} />
                    </div>
                </div>
            ),
        },
        // {
        //     Header: "Irregular?",
        //     accessor: 'irregular',
        //     idAccessor: 'member_id',
        //     Cell: ({ column, row }) => (
        //         <div className="flex items-left">
        //             <div className="ml-4">
        //                 <input type="checkbox" checked={row.values.irregular || false} onChange={e => { handleClIrrOnchange(row.original[column.idAccessor], e.target.checked) }} />
        //             </div>
        //         </div>
        //     ),
        // }
    ], [])

    const guestColumns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: 'Guest.name',
        },
        {
            Header: "Attended?",
            accessor: 'attendance',
            idAccessor: 'guest_id',
            Cell: ({ value, column, row }) => (
                <div className="flex items-left">
                    <div className="ml-4">
                        <input type="checkbox" value={value}
                            className="border-solid w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={row.values.attendance || false} onChange={e => { handleGsAttnOnchange(row.original[column.idAccessor], e.target.checked) }} />
                    </div>
                </div>
            ),
        },
        // {
        //     Header: "Irregular?",
        //     accessor: 'irregular',
        //     idAccessor: 'guest_id',
        //     Cell: ({ column, row }) => (
        //         <div className="flex items-left">
        //             <div className="ml-4">
        //                 <input type="checkbox" checked={row.values.irregular || false} onChange={e => { handleGsIrrOnchange(row.original[column.idAccessor], e.target.checked) }} />
        //             </div>
        //         </div>
        //     ),
        // }
    ], [])



    // const data = React.useMemo(() => cellMembers, [])

    return (
        <div className="h-full bg-gray-100 text-gray-900">
            {report && (
                <main className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                    <div className="flex flex-row justify-start content-start">
                        <button
                            onClick={() => navigate(-1)}
                            className="mr-4 text-black-500 border border-black hover:bg-black hover:text-white active:bg-black-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                        >
                            <i className="fas fa-arrow-left"></i>
                        </button>
                        <h1 className="text-xl font-semibold">Edit Report for : <span className='rounded-full bg-yellow-500/100 px-2 py-1 text-white text-sm'>{moment(report.date, "YYYY-MM-DD").format("YYYY-MM-DD")}</span></h1>
                    </div>
                    <div className="mt-6">
                        <form className="space-y-6">
                            <div className='block w-32'>
                                <label htmlFor="day" className="block text-sm font-medium leading-6 text-gray-900">Date</label>
                                <div className="mt-2">
                                    <input className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"

                                        autoComplete="Date"
                                        required
                                        name="date"
                                        type="date"
                                        value={moment(report.date, "YYYY-MM-DD").format("YYYY-MM-DD")}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            {/* Div flex */}
                            <div className='flex flex-col'>
                                {/* Section 1 */}
                                <div className='flex flex-col'>
                                    <h4 className="text-lg font-sans font-semibold">Section 1: Cell Members</h4>
                                    <p className='font-light hover:underline ease-linear transition-all duration-150'>Indicate attendance details below</p>
                                    <div className="mt-6">
                                        {cellMembers && (<Table columns={columns} data={cellMembers} />)}
                                    </div>
                                </div>
                                {/* Section 2 */}
                                <div className='flex flex-col'>
                                    <h4 className="text-md font-sans font-semibold" htmlFor="testimonies">Testimonies</h4>

                                    <div className="mt-6">
                                        <textarea
                                            id="testimonies"
                                            name="testimonies"
                                            type="text"
                                            rows={6}
                                            autoComplete="testimonies"
                                            required
                                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            value={report.testimonies || ""}
                                            onChange={handleTmChange}
                                        />
                                    </div>
                                    <div className="mt-6 flex flex-row gap-2" htmlFor="evaluations">
                                        <h4 className="text-md font-sans font-semibold">Evaluations</h4>

                                        <p className='text-md font-light hover:underline ease-linear transition-all duration-150'>(Comments, Prayer Requests):</p>
                                    </div>

                                    <div className="mt-6">
                                        <textarea
                                            id="evaluations"
                                            name="evaluations"
                                            type="text"
                                            rows={6}
                                            autoComplete="testimonies"
                                            required
                                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            value={report.evaluations || ""}
                                            onChange={handleEvChange}
                                        />
                                    </div>

                                </div>
                                {/* Section 3 - Guests*/}
                                <div className='mt-8 flex flex-col'>
                                    <h4 className="text-lg font-sans font-semibold">Section 2: Guests</h4>
                                    <p className='font-light hover:underline ease-linear transition-all duration-150'>Indicate attendance details below</p>
                                    <div className="mt-6">
                                        {guests && (<Table columns={guestColumns} data={guests} />)}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="mt-4 mb-6">
                        <div className='flex flex-row justify-end align-end'>
                            <EditButton disabled={!formIsValid} handleSubmit={handleSubmit} name={'Edit Report'} />
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
};

const EditButton = ({ disabled, handleSubmit, name }) => {
    if (disabled) {
        return (
            <button
                disabled={true}
                className="border border-2 bg-yellow-500 border-yellow-500 text-white active:bg-yellow-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-25 cursor-not-allowed" type="button"
            >
                {name}
            </button>
        )
    }
    return (
        <button
            disabled={false}
            onClick={handleSubmit}
            className="hover:text-yellow-500 border border-2 bg-yellow-500 border-yellow-500 hover:bg-white text-white active:bg-yellow-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
        >
            {name}
        </button>
    )
}

EditReport.propTypes = {
    report: PropTypes.object,
    cellMembers: PropTypes.array,
    guests: PropTypes.array,
    getReportByIdRequested: PropTypes.func,
    getReportsRequested: PropTypes.func,
    reportGetRequested: PropTypes.bool,
};

EditReport.defaultProps = {
    report: null,
    cellMembers: [],
    guests: [],
    reportGetRequested: false,
};

const mapStateToProps = (state) => ({
    report: state.report.report,
    cellMembers: state.report.cellMembers,
    guests: state.report.guests,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getReportByIdRequested,
    getReportsRequested,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
    updateReportRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditReport); 