import {
    GET_CELL_MEMBERS_REQUESTED,
    GET_CELL_MEMBERS_SUCCESS,
    GET_CELL_MEMBERS_FAILED,
    GET_CELL_MEMBER_BY_ID_REQUESTED,
    GET_CELL_MEMBER_BY_ID_SUCCESS,
    GET_CELL_MEMBER_BY_ID_FAILED,
    ADD_CELL_MEMBER_REQUESTED,
    ADD_CELL_MEMBER_SUCCESS,
    ADD_CELL_MEMBER_FAILED,
    UPDATE_CELL_MEMBER_REQUESTED,
    UPDATE_CELL_MEMBER_SUCCESS,
    UPDATE_CELL_MEMBER_FAILED,
    DELETE_CELL_MEMBER_REQUESTED,
    DELETE_CELL_MEMBER_SUCCESS,
    DELETE_CELL_MEMBER_FAILED,

} from '../actions/types';

const initialState = {
    cellMembers: [],
    cellMember: {},
    error: null,
    loading: false,
    success: false,

    cellMembersRequested: false,
    cellMembersError: '',
    cellMembersSuccess: false,

    cellMemberByIdRequested: false,
    cellMemberByIdError: '',
    cellMemberByIdSuccess: false,

    addCellMemberRequested: false,
    addCellMemberError: '',
    addCellMemberSuccess: false,

    updateCellMemberRequested: false,
    updateCellMemberError: '',
    updateCellMemberSuccess: false,

    deleteCellMemberRequested: false,
    deleteCellMemberError: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CELL_MEMBERS_REQUESTED:
            return {
                ...state,
                cellMembersRequested: true,
            };
        case GET_CELL_MEMBERS_SUCCESS:
            return {
                ...state,
                cellMembersRequested: false,
                cellMembersSuccess: true,
                cellMembers: action.payload,
            };
        case GET_CELL_MEMBERS_FAILED:
            return {
                ...state,
                cellMembersRequested: false,
                cellMembersError: action.payload,
            };
        case GET_CELL_MEMBER_BY_ID_REQUESTED:
            return {
                ...state,
                cellMemberByIdRequested: true,
            };
        case GET_CELL_MEMBER_BY_ID_SUCCESS:
            return {
                ...state,
                cellMemberByIdRequested: false,
                cellMemberByIdSuccess: true,
                cellMember: action.payload,
            };
        case GET_CELL_MEMBER_BY_ID_FAILED:
            return {
                ...state,
                cellMemberByIdRequested: false,
                cellMemberByIdError: action.payload,
            };
        case ADD_CELL_MEMBER_REQUESTED:
            return {
                ...state,
                addCellMemberRequested: true,
            };
        case ADD_CELL_MEMBER_SUCCESS:
            return {
                ...state,
                addCellMemberRequested: false,
                addCellMemberSuccess: true,
                cellMember: action.payload,
                cellMembers: [...state.cellMembers, action.payload],
            };
        case ADD_CELL_MEMBER_FAILED:
            return {
                ...state,
                addCellMemberRequested: false,
                addCellMemberError: action.payload,
            };
        case UPDATE_CELL_MEMBER_REQUESTED:
            return {
                ...state,
                updateCellMemberRequested: true,
            };
        case UPDATE_CELL_MEMBER_SUCCESS:
            return {
                ...state,
                updateCellMemberRequested: false,
                updateCellMemberSuccess: true,
                cellMember: action.payload,
                cellMembers: state.cellMembers.map(cellMember =>{
                    if (cellMember.id === action.payload.id) {
                        return action.payload;
                    }
                    return cellMember;
                }),
            };
        case UPDATE_CELL_MEMBER_FAILED:
            return {
                ...state,
                updateCellMemberRequested: false,
                updateCellMemberError: action.payload,
            };
        case DELETE_CELL_MEMBER_REQUESTED:
            return {
                ...state,
                deleteCellMemberRequested: true,
            };
        case DELETE_CELL_MEMBER_SUCCESS:
            return {
                ...state,
                deleteCellMemberRequested: false,
                deleteCellMemberSuccess: true,
                cellMember: action.payload,
                cellMembers: state.cellMembers.filter(cellMember => cellMember.id !== action.payload.id),
            };
        case DELETE_CELL_MEMBER_FAILED:
            return {
                ...state,
                deleteCellMemberRequested: false,
                deleteCellMemberError: action
            };
        default:
            return state;
    }
};
