import React, { useMemo, useState, useEffect } from 'react'
import Table, { AvatarCell, SelectColumnFilter, StatusPill } from './../../../components/Table'  // new
import { connect, useDispatch } from 'react-redux'
import { TrashIcon } from '@heroicons/react/outline';
import Modal from '../../../components/Modal';
import {
    getGuestsRequested,
    addGuestRequested,
    updateGuestRequested,
    deleteGuestRequested
} from '../../../actions/guestAction';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Guests = ({
    guests,
    user,
    guestsGetRequested,
    getGuestsRequested: getGuests,
    addGuestRequested: addGuest,
    updateGuestRequested: updateGuest,
    deleteGuestRequested: deleteGuest,
}) => {
    const dispatch = useDispatch();
    // const [guests, setGuests] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    // const [error, setError] = React.useState(null);
    // const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const id = user?.cells[0]?.id;
    const navigate = useNavigate();

    useEffect(() => {
        if (!guestsGetRequested) {
            getGuests(id)
        }
    }, [guestsGetRequested]);

    const columns = React.useMemo(() => [
        {
            Header: "#",
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-4">
                    <p>{cell.row.index + 1}</p>
                </div>
            ),
        },
        {
            Header: "Name",
            accessor: 'name',
            Cell: AvatarCell,
            imgAccessor: "imgUrl",
            emailAccessor: "phoneNumber",
        },
        {
            Header: "Birthday",
            accessor: 'birthday',
            Cell: ({ cell }) => (
                <div className="text-sm text-gray-500">
                    {cell.row.original.birthday ? moment(cell.row.original.birthday, 'YYYY-MM-DD').format('DD MMMM') : 'Not set'}
                </div>
            ),
        },
        {
            Header: "Phone Number",
            accessor: 'phoneNumber',
            Cell: ({ cell }) => (
                <div className="text-sm text-gray-500">
                    {cell.row.original.phoneNumber ? cell.row.original.phoneNumber : 'Not set'}
                </div>
            ),
        },
        // {
        //     Header: "Status",
        //     accessor: 'status',
        //     Cell: StatusPill,
        // },
        {
            Header: "Action",
            accessor: 'id',
            idAccessor: 'id',
            Cell: ({ cell, row, column }) => (
                <div className="flex items-center space-x-1">
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-yellow-100 text-yellow-800"
                        onClick={() => handleEdit(cell.row.original)}
                    >
                        Edit
                    </button>
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-red-100 text-red-800"
                        onClick={() => handleDeleteMember(row.original[column.idAccessor])}
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </div>
            ),
        }
    ], [])

    if (!user || !user.id) {
        navigate('/login');
        return;
    }

    const handleAddMember = () => {
        console.log("Add guest");
        setShowModal(true);
    }

    const handleEdit = (data) => {
        setFormData(data);
        setEdit(true);
        setShowModal(true);
        console.log("Edit guest");
        console.log(data);
    };


    const handleCloseModal = (e) => {
        e.preventDefault();
        setFormData({});
        setShowModal(false);
    };

    const handleEditMember = (e) => {
        e.preventDefault();
        console.log("Edit Cell guest");
        console.log(formData);
        updateGuest({ cellId: id, id: formData.id, data: formData })
        setFormData({});
        setShowModal(false);
        setEdit(false);
    };

    const handleDeleteMember = (data_id) => {
        // e.preventDefault();
        console.log("Delete guest");
        console.log(formData);
        deleteGuest({ cellId: id, id: data_id })
        setShowModal(false);
    };

    const handleSaveMember = (e) => {
        e.preventDefault();
        console.log("Save guest");
        console.log(formData);
        // setGuests(null);
        addGuest({ cellId: id, data: formData });
        // setFormData({});
        setShowModal(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    // console.log(`Member data ${guests}`)
    // const data = React.useMemo(() => guests, [])

    return (
        <div className="h-400 bg-gray-100 text-gray-900">

            <Modal title={edit ? "Edit Guest" : "Add a new guest"} onClose={handleCloseModal} show={showModal} data={formData} onSubmit={edit && edit ? handleEditMember : handleSaveMember}>
                <form className='space-y-6'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Full Name</label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="full name"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.name || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">Phone number</label>
                        <div className="mt-2">
                            <input
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                autoComplete="phone number"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.phoneNumber || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="dob" className="block text-sm font-medium leading-6 text-gray-900">Date of birth</label>
                        <div className="mt-2">
                            <input
                                id="dob"
                                name="birthday"
                                type="date"
                                autoComplete="date of birth"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.birthday || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </form>
            </Modal>
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-between content-between">
                    {/* ❤ */}
                    <h1 className="text-xl font-semibold">Guests</h1>
                    {id && (
                    <button
                        onClick={() => handleAddMember()}
                        className="text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        <i className="fas fa-plus"></i>
                        Add Guest
                    </button>
                    )}
                </div>
                <div className="mt-6">
                    {guests.length > 0 && (<Table columns={columns} data={guests} />)}
                    {guests.length === 0 && (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">No guests found</h1>
                            <p className="text-sm text-gray-500">Add a new guest</p>
                        </div>
                    )}
                    {/* <Table columns={columns} data={data} /> */}
                </div>
                {id === undefined && (
                    <div className="mt-6">
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">This account isn't assinged to a cell yet!</h1>
                            <p className="text-sm text-gray-500">Contact admin.</p>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
}

Guests.propTypes = {
    guests: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    guestsGetRequested: PropTypes.bool.isRequired,
    getGuestsRequested: PropTypes.func.isRequired,
    addGuestRequested: PropTypes.func.isRequired,
    updateGuestRequested: PropTypes.func.isRequired,
    deleteGuestRequested: PropTypes.func.isRequired,
};

Guests.defaultProps = {
    guests: [],
    user: {},
    guestsGetRequested: false,
};

const mapStateToProps = (state) => ({
    guests: state.guest.guests,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getGuestsRequested,
    addGuestRequested,
    updateGuestRequested,
    deleteGuestRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Guests);