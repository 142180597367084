import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getUserRequested, updateUserRequested, updatePasswordRequested } from "../../actions/userAction";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import { useNavigate } from "react-router-dom";

const Settings = ({
    user,
    currentUser,
    userGetRequested,
    getUserRequested: getUser,
    updateUserRequested: updateUser,
    updatePasswordRequested: updatePassword,
}) => {
    const title = "Settings";
    const [formData, setFormData] = React.useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const id = user?.id;

    useEffect(() => {
        if (!userGetRequested) {
            getUser({id});
        }
    }, [userGetRequested]);

    useEffect(() => {
        setFormData(currentUser);
    }, [currentUser]);

    if (!user || !user.id) {
        navigate('/login');
        return;
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === "verifyPassword") {
            if (value !== formData.password) {
                setErrorMessage("Password does not match");
            } else {
                setErrorMessage("");
            }
        }
        setFormData(values => ({ ...values, [name]: value }))
    }

    const handleUpdate = () => {
        console.log("Update User");
        console.log(formData);
        formData.id = id;
        delete formData.verifyPassword;
        if (!formData.password || formData.password === "") {
            delete formData.password;
        }
        updateUser(formData);
    }

    return (
        <div className="h-full bg-gray-100 text-gray-900 pb-8">
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-start content-start">
                    <h1 className="text-xl font-semibold">{title}</h1>
                </div>
                <div className="mt-6">
                    <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 ">
                        <li className="mr-2">
                            <a href="#" className="inline-block p-4 text-gray-800 bg-white rounded-t-lg active ">
                                User Details
                            </a>
                        </li>
                        <li>
                            <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">
                                Automation
                            </a>
                        </li>
                    </ul>
                    <UserDetailsForm 
                    formData={formData} 
                    errorMessage={errorMessage}
                    action={handleUpdate}
                    handleChange={handleChange} />
                </div>
            </main>
        </div>
    );
}

const UserDetailsForm = ({ formData, handleChange, action, errorMessage }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowVerifyPassword = () => {
        setShowVerifyPassword(!showVerifyPassword);
    };
    return (
        <div className="mt-6 w-auto max-w-sm">
            <form className='space-y-3'>
                <div>
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                    <div className="mt-2">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="cell name"
                            required
                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            value={formData?.name || ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                    <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="enter email"
                            required
                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            value={formData?.email || ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                    <div className="mt-2">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            autoComplete="enter username"
                            required
                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            value={formData?.username || ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {errorMessage && <p className="text-sm text-red-500 m-0">{errorMessage}</p>}

                <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">New Password</label>
                    <div className="mt-2 relative">
                        <input
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="new-password"
                            required
                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            value={formData?.password || ""}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            onClick={toggleShowPassword}
                        >
                            {showPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                        </button>
                    </div>
                </div>

                <div>
                    <label htmlFor="verifyPassword" className="block text-sm font-medium leading-6 text-gray-900">Verify Password</label>
                    <div className="mt-2 relative">
                        <input
                            id="verifyPassword"
                            name="verifyPassword"
                            type={showVerifyPassword ? "text" : "password"}
                            autoComplete="new-password"
                            required
                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            value={formData?.verifyPassword || ""}
                            onChange={handleChange}
                        />
                        <button
                            type="button"
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                            onClick={toggleShowVerifyPassword}
                        >
                            {showVerifyPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                        </button>
                    </div>
                </div>

                <div className="text-right">
                    <button
                    type="button"
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    onClick={action}
                    >
                        Update
                    </button>
                </div>

            </form>
        </div>
    )
}


Settings.propTypes = {
    updatePasswordRequested: PropTypes.func,
    updateUserRequested: PropTypes.func,
    user: PropTypes.object,
    userGetRequested: PropTypes.bool,
};

Settings.defaultProps = {
    userGetRequested: false,
    currentUser: {},
};

const mapStateToProps = (state) => ({
    // reports: state.report.reports,
    currentUser: state.user.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUserRequested,
    getUserRequested,
    updatePasswordRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);