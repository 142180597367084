import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { Report, GetReportById, GetReportDetails } from "../../reducers/reportReducer";
import Table from '../../components/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getReportDetailsRequested,
    getReportsRequested,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
    submitNewReportRequested
} from "../../actions/reportAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

/**
 * Renders a form for adding a new report, including fields for cell members, guests, testimonies, evaluations, and date.
 * @param {Object} props - The component props.
 * @param {Array} props.cellMembers - An array of cell members.
 * @param {Array} props.guests - An array of guests.
 * @param {Object} props.report - The report object.
 * @param {boolean} props.reportDetailsRequested - A boolean indicating whether report details have been requested.
 * @param {Function} props.updateAttendance - A function to update attendance.
 * @param {Function} props.updateGuestAttendance - A function to update guest attendance.
 * @param {Function} props.updateTestimonies - A function to update testimonies.
 * @param {Function} props.updateEvaluations - A function to update evaluations.
 * @param {Function} props.updateDate - A function to update the report date.
 * @param {Function} props.submitNewReportRequested - A function to submit a new report.
 * @param {Function} props.getReportDetailsRequested - A function to get report details.
 * @returns {JSX.Element} - The rendered component.
 */
const AddReport = ({
    cellMembers,
    guests,
    report,
    reportDetailsRequested,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
    getReportsRequested: getReport,
    submitNewReportRequested: submitReport,
    getReportDetailsRequested: reportGetDetails,
}) => {
    // const [cellId, setCellId] = React.useState(null);
    // const location = useLocation(); 
    const navigate = useNavigate();
    const [errors, setErrors] = React.useState({});
    const [formIsValid, setFormIsValid] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    // const [report, setReport] = React.useState(null);
    // const [testimonies, setTestimonies] = React.useState("");
    // const [evaluations, setEvaluations] = React.useState("");
    // const [cellMembers, setCellMembers] = React.useState([]);
    // const [guests, setGuests] = React.useState([]);
    // const [date, setDate] = React.useState("");
    // const dispatch = useDispatch();
    const { cellId } = useParams();

    React.useEffect(() => {
        const isValid = handleValidaton();
        setFormIsValid(isValid);
    }, [report]);

    React.useEffect(() => {
        if (!reportDetailsRequested) {
            reportGetDetails({ cellId });
        }
    }, [reportDetailsRequested]);

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleClAttnOnchange = (id, value) => {
        updateAttendance({ id, value });
    }

    const handleClIrrOnchange = (id, value) => {
        if (cellMembers.length > 0) {
            cellMembers.map(member => {
                if (member.id === id) {
                    member.irregular = value;
                    return { ...member };
                }
                else {
                    return member;
                }
            })
            // setCellMembers([...cellMembers])
        }
    }

    /**
     * 
     * @param {guest_id} id 
     * @param {checkbox value} value 
     */
    const handleGsAttnOnchange = (id, value) => {
        updateGuestAttendance({ id, value });
    }

    const handleGsIrrOnchange = (id, value) => {
        if (guests.length > 0) {
            guests.map(member => {
                if (member.id === id) {
                    member.irregular = value;
                    return { ...member };
                }
                else {
                    return member;
                }

            })
            // setGuests([...guests])
        }
    }

    const handleTmChange = (e) => {
        updateTestimonies(e.target.value);
    }

    const handleEvChange = (e) => {
        updateEvaluations(e.target.value);
    }

    const handleDateChange = (e) => {
        updateDate(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Save report");
        // console.log(cellMembers);
        // console.log(guests);
        // console.log(report);
        // console.log(report.date);
        submitReport({
            cell_id: cellId,
            cellMemberAttendance: cellMembers,
            guestAttendance: guests,
            evaluations: report.evaluations,
            testimonies: report.testimonies,
            date: report.date,
        })
        notify("Report created successfully!");
        getReport(cellId);
        navigate('/dashboard/reports');
    }

    const handleValidaton = () => {
        let errors = {};
        let formIsValid = true;
        if (report != null && (report.date == null || report.date === "")) {
            formIsValid = false;
            errors["date"] = "Date cannot be empty";
        }

        setErrors(errors);
        return formIsValid;
    }

    const columns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Attended?",
            accessor: 'attendance',
            idAccessor: 'id',
            Cell: ({ value, column, row }) => (
                <div className="flex items-left">
                    <div className="ml-4">
                        {/* <input id={row.original.id} type="checkbox" value={value} checked={row.values.attendance || false} onChange={searchEvent} /> */}
                        <input id={row.original.id} type="checkbox" value={value} className="border-solid w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={row.values.attendance || false} onChange={e => { handleClAttnOnchange(row.original[column.idAccessor], e.target.checked) }} />
                    </div>
                </div>
            ),
        },
        // {
        //     Header: "Irregular?",
        //     accessor: 'irregular',
        //     idAccessor: 'id',
        //     Cell: ({ column, row, value }) => (
        //         <div className="flex items-left">
        //             <div className="ml-4">
        //                 <input id={row.original.id} type="checkbox" value={value} checked={row.values.irregular || false} onChange={e => { handleClIrrOnchange(row.original[column.idAccessor], e.target.checked) }} />
        //             </div>
        //         </div>
        //     ),
        // }
    ], [])

    const guestColumns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Attended?",
            accessor: 'attendance',
            idAccessor: 'id',
            Cell: ({ value, column, row }) => (
                <div className="flex items-left">
                    <div className="ml-4">
                        <input id={row.original.id} type="checkbox"
                            className="border-solid w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            value={value} checked={row.values.attendance || false} onChange={e => { handleGsAttnOnchange(row.original[column.idAccessor], e.target.checked) }} />
                    </div>
                </div>
            ),
        },
        // {
        //     Header: "Irregular?",
        //     accessor: 'irregular',
        //     idAccessor: 'id',
        //     Cell: ({ column, row, value }) => (
        //         <div className="flex items-left">
        //             <div className="ml-4">
        //                 <input id={row.original.id} type="checkbox" value={value} checked={row.values.irregular || false} onChange={e => { handleGsIrrOnchange(row.original[column.idAccessor], e.target.checked) }} />
        //             </div>
        //         </div>
        //     ),
        // }
    ], [])



    // const data = React.useMemo(() => cellMembers, [])

    return (
        <div className="h-full bg-gray-100 text-gray-900 ">
            {report && (
                <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                    <div className="flex flex-row justify-start content-start">
                        <button
                            onClick={() => navigate(-1)}
                            className="mr-4 text-black-500 border border-black hover:bg-black hover:text-white active:bg-black-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                        >
                            <i className="fas fa-arrow-left"></i>
                        </button>
                        <h1 className="text-xl font-semibold">New Cell Reports</h1>
                    </div>
                    <div className="mt-6">
                        <form className="space-y-6">
                            <div className='block w-32'>
                                <label htmlFor="day" className="block text-sm font-medium leading-6 text-gray-900">Date</label>
                                <div className="mt-2">
                                    <input className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"

                                        autoComplete="Date"
                                        required
                                        name="date"
                                        type="date"
                                        value={report.date || ""}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            {/* Div flex */}
                            <div className='flex flex-col'>
                                {/* Section 1 */}
                                <div className='flex flex-col'>
                                    <h4 className="text-lg font-sans font-semibold">Section 1: Cell Members</h4>
                                    <p className='font-light hover:underline ease-linear transition-all duration-150'>Indicate attendance details below</p>
                                    <div className="mt-6">
                                        {cellMembers && cellMembers.length > 0 && (<Table columns={columns} data={cellMembers} />)}
                                        {cellMembers && cellMembers.length === 0 && (
                                            <div className="flex flex-col items-start justify-start">
                                                <h1 className="text-lg font-semibold">No cell members found</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* Section 2 */}
                                <div className='flex flex-col'>
                                    <h4 className="text-md font-sans font-semibold" htmlFor="testimonies">Testimonies</h4>

                                    <div className="mt-6">
                                        <textarea
                                            id="testimonies"
                                            name="testimonies"
                                            type="text"
                                            rows={6}
                                            autoComplete="testimonies"
                                            required
                                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            value={report.testimonies || ""}
                                            onChange={handleTmChange}
                                        />
                                    </div>
                                    <div className="mt-6 flex flex-row gap-2" htmlFor="evaluations">
                                        <h4 className="text-md font-sans font-semibold">Evaluations</h4>

                                        <p className='text-md font-light hover:underline ease-linear transition-all duration-150'>(Comments, Prayer Requests):</p>
                                    </div>

                                    <div className="mt-6">
                                        <textarea
                                            id="evaluations"
                                            name="evaluations"
                                            type="text"
                                            rows={6}
                                            autoComplete="testimonies"
                                            required
                                            className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                            value={report.evaluations || ""}
                                            onChange={handleEvChange}
                                        />
                                    </div>

                                </div>
                                {/* Section 3 - Guests*/}
                                <div className='mt-8 flex flex-col'>
                                    <h4 className="text-lg font-sans font-semibold">Section 2: Guests</h4>
                                    <p className='font-light hover:underline ease-linear transition-all duration-150'>Indicate attendance details below</p>
                                    <div className="mt-6">
                                        {guests && guests.length > 0 && (<Table columns={guestColumns} data={guests} />)}
                                        {guests && guests.length === 0 && (
                                            <div className="flex flex-col items-start justify-start">
                                                <h1 className="text-lg font-semibold">No guests found</h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="mt-4 mb-8">
                        <div className='flex flex-row justify-end align-end'>
                            <SubmitButton disabled={!formIsValid} handleSubmit={handleSubmit} />
                            {/* <button
                                onClick={handleSubmit}
                                disabled={true}
                                className="hover:text-emerald-500 border border-2 bg-emerald-500 border-emerald-500 hover:bg-white text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-25 cursor-not-allowed" type="button"
                            >
                                Save Report
                            </button> */}
                        </div>
                    </div>
                </main>
            )}
        </div>
    );
};

const SubmitButton = ({ disabled, handleSubmit }) => {
    if (disabled) {
        return (
            <button
                disabled={true}
                className="border border-2 bg-emerald-500 border-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-25 cursor-not-allowed" type="button"
            >
                Save Report
            </button>
        )
    }
    return (
        <button
            disabled={false}
            onClick={handleSubmit}
            className="hover:text-emerald-500 border border-2 bg-emerald-500 border-emerald-500 hover:bg-white text-white active:bg-emerald-600 font-bold uppercase text-sm px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
        >
            Save Report
        </button>
    )
}
AddReport.propTypes = {
    report: PropTypes.object,
    cellMembers: PropTypes.array,
    guests: PropTypes.array,
    getReportDetailsRequested: PropTypes.func,
    getReportsRequested: PropTypes.func,
    reportDetailsRequested: PropTypes.bool,
}

AddReport.defaultProps = {
    report: null,
    cellMembers: [],
    guests: [],
    reportDetailsRequested: false,
}

const mapStateToProps = (state) => ({
    report: state.report.report,
    cellMembers: state.report.cellMembers,
    guests: state.report.guests,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getReportDetailsRequested,
    getReportsRequested,
    submitNewReportRequested,
    updateAttendance,
    updateGuestAttendance,
    updateTestimonies,
    updateEvaluations,
    updateDate,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AddReport);