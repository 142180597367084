import API from '../utils/api';
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    GET_CELL_MEMBERS_REQUESTED,
    GET_CELL_MEMBERS_SUCCESS,
    GET_CELL_MEMBERS_FAILED,
    GET_CELL_MEMBER_BY_ID_REQUESTED,
    GET_CELL_MEMBER_BY_ID_SUCCESS,
    GET_CELL_MEMBER_BY_ID_FAILED,
    ADD_CELL_MEMBER_REQUESTED,
    ADD_CELL_MEMBER_SUCCESS,
    ADD_CELL_MEMBER_FAILED,
    UPDATE_CELL_MEMBER_REQUESTED,
    UPDATE_CELL_MEMBER_SUCCESS,
    UPDATE_CELL_MEMBER_FAILED,
    DELETE_CELL_MEMBER_REQUESTED,
    DELETE_CELL_MEMBER_SUCCESS,
    DELETE_CELL_MEMBER_FAILED,

} from '../actions/types';

import {
    getCellMembersSuccess,
    getCellMembersFailed,
    getCellMemberByIdSuccess,
    getCellMemberByIdFailed,
    addCellMemberSuccess,
    addCellMemberFailed,
    updateCellMemberSuccess,
    updateCellMemberFailed,
    deleteCellMemberSuccess,
    deleteCellMemberFailed,
} from '../actions/cellMemberAction';

/* ----- CELL MEMBERS ----- */
export function* getCellMembers({payload}) {
    try {
        const response = yield call(API.getData,`cells/${payload}/cellMembers`);
        yield put(getCellMembersSuccess(response));
    } catch (error) {
        yield put(getCellMembersFailed(error.response));
    }
}

export function* watchGetCellMembers() {
    yield takeEvery(GET_CELL_MEMBERS_REQUESTED, getCellMembers);
}

export function* getCellMemberById({payload}) {
    try {
        const response = yield call(API.getData,`cells/${payload}/cellMembers/${payload.id}`);
        yield put(getCellMemberByIdSuccess(response.data));
    } catch (error) {
        yield put(getCellMemberByIdFailed(error.response));
    }
}

export function* watchGetCellMemberById() {
    yield takeLatest(GET_CELL_MEMBER_BY_ID_REQUESTED, getCellMemberById);
}

export function* addCellMember({payload}) {
    try {
        const response = yield call(API.postData,`cells/${payload.id}/cellMembers`, payload.data);
        yield put(addCellMemberSuccess(response));
    } catch (error) {
        yield put(addCellMemberFailed(error.response));
    }
}

export function* watchAddCellMember() {
    yield takeLatest(ADD_CELL_MEMBER_REQUESTED, addCellMember);
}

export function* updateCellMember({payload}) {
    try {
        const response = yield call(API.putData,`cells/${payload.cellId}/cellMembers/${payload.id}`, payload.data);
        yield put(updateCellMemberSuccess(response));
    } catch (error) {
        yield put(updateCellMemberFailed(error.response));
    }
}

export function* watchUpdateCellMember() {
    yield takeLatest(UPDATE_CELL_MEMBER_REQUESTED, updateCellMember);
}

export function* deleteCellMember({payload}) {
    try {
        const response = yield call(API.deleteData,`cells/${payload.cellId}/cellMembers/${payload.id}`);
        yield put(deleteCellMemberSuccess({...response, id: payload.id}));
    } catch (error) {
        yield put(deleteCellMemberFailed(error.response));
    }
}

export function* watchDeleteCellMember() {
    yield takeLatest(DELETE_CELL_MEMBER_REQUESTED, deleteCellMember);
}

export default function* rootSaga() {
    yield takeEvery(GET_CELL_MEMBERS_REQUESTED, getCellMembers);
    yield takeEvery(GET_CELL_MEMBER_BY_ID_REQUESTED, getCellMemberById);
    yield takeEvery(ADD_CELL_MEMBER_REQUESTED, addCellMember);
    yield takeEvery(UPDATE_CELL_MEMBER_REQUESTED, updateCellMember);
    yield takeEvery(DELETE_CELL_MEMBER_REQUESTED, deleteCellMember);
}

