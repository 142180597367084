import {
    GET_CELLS_REQUESTED,
    GET_CELLS_SUCCESS,
    GET_CELLS_FAILED,
    GET_CELL_BY_ID_REQUESTED,
    GET_CELL_BY_ID_SUCCESS,
    GET_CELL_BY_ID_FAILED,
    ADD_CELL_REQUESTED,
    ADD_CELL_SUCCESS,
    ADD_CELL_FAILED,
    UPDATE_CELL_REQUESTED,
    UPDATE_CELL_SUCCESS,
    UPDATE_CELL_FAILED,
    DELETE_CELL_REQUESTED,
    DELETE_CELL_SUCCESS,
    DELETE_CELL_FAILED,
    GET_CELL_MEMBERS_REQUESTED,
    GET_CELL_MEMBERS_SUCCESS,
    GET_CELL_MEMBERS_FAILED,
    GET_CELL_MEMBER_BY_ID_REQUESTED,
    GET_CELL_MEMBER_BY_ID_SUCCESS,
    GET_CELL_MEMBER_BY_ID_FAILED,
    ADD_CELL_MEMBER_REQUESTED,
    ADD_CELL_MEMBER_SUCCESS,
    ADD_CELL_MEMBER_FAILED,
    UPDATE_CELL_MEMBER_REQUESTED,
    UPDATE_CELL_MEMBER_SUCCESS,
    UPDATE_CELL_MEMBER_FAILED,

} from './types';

/* ----- CELLS ----- */
export const getCellsRequested = () => ({ type: GET_CELLS_REQUESTED });
export const getCellsSuccess = (data) => ({ type: GET_CELLS_SUCCESS, payload: data });
export const getCellsFailed = (data) => ({ type: GET_CELLS_FAILED, payload: data });

export const getCellByIdRequested = (id) => ({ type: GET_CELL_BY_ID_REQUESTED, payload: id });
export const getCellByIdSuccess = (data) => ({ type: GET_CELL_BY_ID_SUCCESS, payload: data });
export const getCellByIdFailed = (data) => ({ type: GET_CELL_BY_ID_FAILED, payload: data });

export const addCellRequested = (data) => ({ type: ADD_CELL_REQUESTED, payload: data });
export const addCellSuccess = (data) => ({ type: ADD_CELL_SUCCESS, payload: data });
export const addCellFailed = (data) => ({ type: ADD_CELL_FAILED, payload: data });

export const updateCellRequested = (data) => ({ type: UPDATE_CELL_REQUESTED, payload: data });
export const updateCellSuccess = (data) => ({ type: UPDATE_CELL_SUCCESS, payload: data });
export const updateCellFailed = (data) => ({ type: UPDATE_CELL_FAILED, payload: data });

export const deleteCellRequested = (id) => ({ type: DELETE_CELL_REQUESTED, id: id });
export const deleteCellSuccess = (data) => ({ type: DELETE_CELL_SUCCESS, payload: data });
export const deleteCellFailed = (data) => ({ type: DELETE_CELL_FAILED, payload: data });

export const getCellStatsRequested = (id) => ({ type: 'GET_CELL_STATS_REQUESTED', id: id });
export const getCellStatsSuccess = (data) => ({ type: 'GET_CELL_STATS_SUCCESS', payload: data });
export const getCellStatsFailed = (data) => ({ type: 'GET_CELL_STATS_FAILED', payload: data });

export const getCellUpcomingBirthdaysRequested = (id) => ({ type: 'GET_CELL_UPCOMING_BIRTHDAYS_REQUESTED', id: id });
export const getCellUpcomingBirthdaysSuccess = (data) => ({ type: 'GET_CELL_UPCOMING_BIRTHDAYS_SUCCESS', payload: data });
export const getCellUpcomingBirthdaysFailed = (data) => ({ type: 'GET_CELL_UPCOMING_BIRTHDAYS_FAILED', payload: data });

/* ----- CELL MEMBERS ----- */
export const getCellMembersRequested = (id) => ({ type: GET_CELL_MEMBERS_REQUESTED, payload: id });
export const getCellMembersSuccess = (data) => ({ type: GET_CELL_MEMBERS_SUCCESS, payload: data });
export const getCellMembersFailed = (data) => ({ type: GET_CELL_MEMBERS_FAILED, payload: data });

export const getCellMemberByIdRequested = (data) => ({ type: GET_CELL_MEMBER_BY_ID_REQUESTED, payload: data });
export const getCellMemberByIdSuccess = (data) => ({ type: GET_CELL_MEMBER_BY_ID_SUCCESS, payload: data });
export const getCellMemberByIdFailed = (data) => ({ type: GET_CELL_MEMBER_BY_ID_FAILED, payload: data });

export const addCellMemberRequested = (data) => ({ type: ADD_CELL_MEMBER_REQUESTED, payload: data });
export const addCellMemberSuccess = (data) => ({ type: ADD_CELL_MEMBER_SUCCESS, payload: data });
export const addCellMemberFailed = (data) => ({ type: ADD_CELL_MEMBER_FAILED, payload: data });

export const updateCellMemberRequested = (data) => ({ type: UPDATE_CELL_MEMBER_REQUESTED, payload: data });
export const updateCellMemberSuccess = (data) => ({ type: UPDATE_CELL_MEMBER_SUCCESS, payload: data });
export const updateCellMemberFailed = (data) => ({ type: UPDATE_CELL_MEMBER_FAILED, payload: data });

