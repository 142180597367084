import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import heroImage from '../../assets/hero-image.jpeg';
import logo from '../../assets/cellies.png';

import './../../App.css';

function Home() {
    const navigate = useNavigate();
    // useEffect(() => {
    //     const token = localStorage.getItem('token');
    //     if(!token) {
    //         navigate('/');
    //     }
    //   }
    // );
    // check local storage
    // if (localStorage.getItem('token')) {
    //     console.log('token found');
    //     // redirect to dashboard
    //     navigate('/dashboard');
    // } else {
    //     console.log('token not found');
    //     // redirect to login
    //     navigate('/login');

    // }
    return (
        <div className="flex flex-col items-center justify-center bg-gray-100">
            <nav className="flex items-center justify-between w-full">
                <div className="flex items-center px-4 ">
                    <img src={logo} alt="Logo" className="w-12 h-12 ml-4 mr-4" />
                    <h1 className="text-2xl font-bold">Cell Report</h1>
                </div>
                <Link to="/login" className="bg-indigo-600 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded m-4">
                    Login
                </Link>
            </nav>
            <div className="flex flex-col md:flex-row items-center justify-center bg-gray-100 p-8 mt-8">
                <div className="w-full md:w-1/2 lg:w-1/2 px-4 mb-8">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="text-center md:text-left lg:text-left">
                            <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Welcome to Cellies</h2>
                            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Manage your cell groups with ease</p>
                            <p className="max-w-xl mt-5 text-sm text-gray-500">Cellies is a powerful tool for managing your cells. With features like inventory management, cell member management, and more, you'll be able to keep track of everything in one place.</p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/2 px-4 md:px-8 mb-8">
                    <img src={heroImage} alt="hero" className="w-full h-full object-cover rounded-lg" />
                </div>
            </div>
            <div className="w-full bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Features</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">What Cellies can do for you</p>
                    </div>
                    <div className="mt-20">
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                                <div className="bg-white rounded-lg shadow p-6">
                                    <div className="flex items-center mb-3">
                                        <div className="bg-indigo-600 rounded-md p-3">
                                            <svg className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clipRule="evenodd" />
                                                <path d="M10 4a1 1 0 011 1v3.5h2a1 1 0 110 2h-2v1.5a1 1 0 11-2 0V10H7a1 1 0 110-2h2V5a1 1 0 011-1z" />
                                            </svg>
                                        </div>
                                        <div className="ml-4">
                                            <h3 className="text-lg font-medium text-gray-900">Inventory Management</h3>
                                            <p className="mt-2 text-base text-gray-500">Keep track of your cell inventory with ease. Add, edit, and delete cells as needed.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                                <div className="bg-white rounded-lg shadow p-6">
                                    <div className="flex items-center mb-3">
                                        <div className="bg-indigo-600 rounded-md p-3">
                                            <svg className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clipRule="evenodd" />
                                                <path d="M10 4a1 1 0 011 1v3.5h2a1 1 0 110 2h-2v1.5a1 1 0 11-2 0V10H7a1 1 0 110-2h2V5a1 1 0 011-1z" />
                                            </svg>
                                        </div>
                                        <div className="ml-4">
                                            <h3 className="text-lg font-medium text-gray-900">Attendance Tracking</h3>
                                            <p className="mt-2 text-base text-gray-500">Track your cell members attendance weekly and more.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                                <div className="bg-white rounded-lg shadow p-6">
                                    <div className="flex items-center mb-3">
                                        <div className="bg-indigo-600 rounded-md p-3">
                                            <svg className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clipRule="evenodd" />
                                                <path d="M10 4a1 1 0 011 1v3.5h2a1 1 0 110 2h-2v1.5a1 1 0 11-2 0V10H7a1 1 0 110-2h2V5a1 1 0 011-1z" />
                                            </svg>
                                        </div>
                                        <div className="ml-4">
                                            <h3 className="text-lg font-medium text-gray-900">Feature 3</h3>
                                            <p className="mt-2 text-base text-gray-500">Feature 3 description.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
            

export default Home;

