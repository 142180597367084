import UserReducer from './userReducer';    // import the reducer
import { combineReducers } from 'redux';  // import the combineReducers function
import cellMembersReducer from './cellMemberReducer';
import cellLeaderReducer from './cellLeaderReducer';
import GuestReducer from './guestReducer';
import ReportReducer from './reportReducer';
import AuthReducer from './authReducer';
import cellReducer from './cellReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    cellMember: cellMembersReducer,
    report: ReportReducer,
    cell: cellReducer,
    cellLeader: cellLeaderReducer,
    guest: GuestReducer,
    user: userReducer,
});

export default rootReducer;