import {
    GET_CELLS_REQUESTED,
    GET_CELLS_SUCCESS,
    GET_CELLS_FAILED,
    GET_CELL_BY_ID_REQUESTED,
    GET_CELL_BY_ID_SUCCESS,
    GET_CELL_BY_ID_FAILED,
    ADD_CELL_REQUESTED,
    ADD_CELL_SUCCESS,
    ADD_CELL_FAILED,
    UPDATE_CELL_REQUESTED,
    UPDATE_CELL_SUCCESS,
    UPDATE_CELL_FAILED,
    DELETE_CELL_REQUESTED,
    DELETE_CELL_SUCCESS,
    DELETE_CELL_FAILED,
} from '../actions/types';

const initialState = {
    cells: [],
    cell: null,
    cellStat: {},
    cellUpcomingBirthdays: [],
    error: null,
    loading: false,
    success: false,

    cellStatsGetRequested: false,
    cellUpcomingBirthdaysGetRequested: false,

    cellDeleteRequested: false,
    cellDeleteError: '',
    cellDeleteSuccess: false,

    cellPostRequested: false,
    cellPostError: '',
    cellPostSuccess: false,

    cellPutRequested: false,
    cellPutError: '',
    cellPutSuccess: false,

    cellGetRequested: false,
    cellGetError: '',
    cellGetSuccess: false,

    cellsGetRequested: false,
    cellsGetError: '',
    cellsGetSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CELLS_REQUESTED:
            return {
                ...state,
                cellsGetRequested: true,
            };
        case GET_CELLS_SUCCESS:
            return {
                ...state,
                cellsGetRequested: false,
                cellsGetSuccess: true,
                cells: action.payload,
            };
        case GET_CELLS_FAILED:
            return {
                ...state,
                cellsGetRequested: false,
                cellsGetError: action.payload,
            };
        case GET_CELL_BY_ID_REQUESTED:
            return {
                ...state,
                cellGetRequested: true,
            };
        case GET_CELL_BY_ID_SUCCESS:
            return {
                ...state,
                cellGetRequested: false,
                cellGetSuccess: true,
                cell: action.payload,
            };
        case GET_CELL_BY_ID_FAILED:
            return {
                ...state,
                cellGetRequested: false,
                cellGetError: action.payload,
            };
        case ADD_CELL_REQUESTED:
            return {
                ...state,
                cellPostRequested: true,
            };
        case ADD_CELL_SUCCESS:
            return {
                ...state,
                cellPostRequested: false,
                cellPostSuccess: true,
                cells: [...state.cells, action.payload],
            };
        case ADD_CELL_FAILED:
            return {
                ...state,
                cellPostRequested: false,
                cellPostError: action.payload,
            };
        case UPDATE_CELL_REQUESTED:
            return {
                ...state,
                cellPutRequested: true,
            };
        case UPDATE_CELL_SUCCESS:
            return {
                ...state,
                cellPutRequested: false,
                cellPutSuccess: true,
                cells: state.cells.map(cell => {
                    if (cell.id === action.payload.id) {
                        return action.payload;
                    }
                    return cell;
                }),
            };
        case UPDATE_CELL_FAILED:
            return {
                ...state,
                cellPutRequested: false,
                cellPutError: action.payload,
            };
        case DELETE_CELL_REQUESTED:
            return {
                ...state,
                cellDeleteRequested: true,
            };
        case DELETE_CELL_SUCCESS:
            return {
                ...state,
                cellDeleteRequested: false,
                cellDeleteSuccess: true,
                cells: state.cells.filter(cell => cell.id !== action.payload.id),
            };
        case DELETE_CELL_FAILED:
            return {
                ...state,
                cellDeleteRequested: false,
                cellDeleteError: action.payload,
            };
        case 'GET_CELL_STATS_REQUESTED':
            return {
                ...state,
                cellStatsGetRequested: true,
            };
        case 'GET_CELL_STATS_SUCCESS':
            return {
                ...state,
                cellStatsGetRequested: false,
                cellStat: action.payload,
            };
        case 'GET_CELL_STATS_GET_FAILED':
            return {
                ...state,
                cellStatsGetRequested: false,
                error: action.payload,
            };
        case 'GET_CELL_UPCOMING_BIRTHDAYS_REQUESTED':
            return {
                ...state,
                cellUpcomingBirthdaysGetRequested: true,
            };
        case 'GET_CELL_UPCOMING_BIRTHDAYS_SUCCESS':
            return {
                ...state,
                cellUpcomingBirthdaysGetRequested: false,
                cellUpcomingBirthdays: action.payload,
            };
        case 'GET_CELL_UPCOMING_BIRTHDAYS_FAILED':
            return {
                ...state,
                cellUpcomingBirthdaysGetRequested: false,
                error: action.payload,
            };
        default:
            return state;
    }
};