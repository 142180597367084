import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react';
// import { createPopper } from "@popperjs/core";
import { ToastContainer, toast } from 'react-toastify';
import { DocumentReportIcon } from '@heroicons/react/solid'
import { UsersIcon, UserGroupIcon, OfficeBuildingIcon, DocumentIcon } from '@heroicons/react/outline'
import Footer from "../../components/Footer";
import PropTypes from 'prop-types';
import React, { Fragment, useState } from "react";
import { bindActionCreators } from "redux";
import { onLogoutRequested } from '../../actions/authAction';
import { connect } from "react-redux";

const Dashboard = ({ user, onLogoutRequested: logoutUser }) => {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    if (!user || !user.id) {
        navigate('/login');
        return;
    }
    const name = user?.name;

    //user role = 1, 2, 3
    const userRole = user?.role;
    // const cellCount = user?.cells?.length;
    // let cellMemberCount = 0;

    // let guestCount = 0;
    // if (userRole === 1) {
    //     cellMemberCount = user?.cells.reduce((acc, curr) => {
    //         return acc + curr.cellMemberCount;
    //     }, 0);
    //     guestCount = user.cells.reduce((acc, curr) => {
    //         return acc + curr.guestCount;
    //     }, 0);
    // }

    const logout = () => {
        logoutUser();
    };

    // const handleMobileMenuClick = () => {
    //     setIsMobileMenuOpen(false);
    // };

    return (
        <div>

            <ToastContainer />
            <main className="relative h-full overflow-hidden bg-gray-100 dark:bg-gray-800">
                <div className="flex flex-row items-start justify-between">
                    {/* Side Nav */}
                    <div className="relative h-screen w-0 invisible lg:block lg:w-96 lg:min-w-max lg:visible">
                        <div className="h-full bg-white dark:bg-gray-700">
                            <div className="flex items-center justify-start pt-6 ml-8">
                                <p className="text-xl font-bold dark:text-white">
                                    Cell Report
                                </p>
                            </div>
                            <nav className="mt-6">
                                <div>

                                    <NavLink to="/dashboard/"
                                        className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1472 992v480q0 26-19 45t-45 19h-384v-384h-256v384h-384q-26 0-45-19t-19-45v-480q0-1 .5-3t.5-3l575-474 575 474q1 2 1 6zm223-69l-62 74q-8 9-21 11h-3q-13 0-21-7l-692-577-692 577q-12 8-24 7-13-2-21-11l-62-74q-8-10-7-23.5t11-21.5l719-599q32-26 76-26t76 26l244 204v-195q0-14 9-23t23-9h192q14 0 23 9t9 23v408l219 182q10 8 11 21.5t-7 23.5z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span className="mx-2 text-sm font-normal">
                                            Home
                                        </span>
                                    </NavLink>
                                    {(userRole === 2) && <NavLink to="/dashboard/cells"
                                        className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        {/* <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                                                </path>
                                            </svg>
                                        </span> */}
                                        <OfficeBuildingIcon className="h-5 w-5" />
                                        <span className="mx-2 text-sm font-normal">
                                            Cells
                                            {/* <span className="w-4 h-2 p-1 ml-4 text-xs text-gray-400 bg-gray-200 rounded-lg">
                                                {cellCount}
                                            </span> */}
                                        </span>
                                    </NavLink>
                                    }
                                    {userRole === 1 && <NavLink to="/dashboard/cellmembers"
                                        className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        {/* <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                                                </path>
                                            </svg>
                                        </span> */}
                                        <UserGroupIcon className="h-5 w-5" />
                                        <span className="mx-2 text-sm font-normal">
                                            Cell Members
                                            {/* <span className="w-4 h-2 p-1 ml-4 text-xs text-gray-400 bg-gray-200 rounded-lg">
                                                {cellMemberCount}
                                            </span> */}
                                        </span>
                                    </NavLink>
                                    }
                                    {userRole === 1 && <NavLink to="/dashboard/guests"
                                        className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        <span className="text-left">
                                            {/* <svg width="20" height="20" fill="currentColor" viewBox="0 0 2048 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1070 1178l306-564h-654l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71 348 71 286 191 191 286 71 348z">
                                                </path>
                                            </svg> */}
                                        </span>
                                        <UsersIcon className="h-5 w-5" />
                                        <span className="mx-2 text-sm font-normal">
                                            Guests
                                            {/* <span className="w-4 h-2 p-1 ml-4 text-xs text-gray-400 bg-gray-200 rounded-lg">
                                                {guestCount}
                                            </span> */}
                                        </span>
                                    </NavLink>
                                    }
                                    {userRole === 1 && <NavLink to="/dashboard/reports" className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        {/* <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1728 608v704q0 92-66 158t-158 66h-1216q-92 0-158-66t-66-158v-960q0-92 66-158t158-66h320q92 0 158 66t66 158v32h672q92 0 158 66t66 158z">
                                                </path>
                                            </svg>
                                        </span> */}
                                        <DocumentReportIcon className="h-5 w-5" />
                                        <span className="mx-4 text-sm font-normal">
                                            Reports
                                        </span>
                                    </NavLink>
                                    }
                                    {userRole === 2 && <NavLink to="/dashboard/users" className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        {/* <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1728 608v704q0 92-66 158t-158 66h-1216q-92 0-158-66t-66-158v-960q0-92 66-158t158-66h320q92 0 158 66t66 158v32h672q92 0 158 66t66 158z">
                                                </path>
                                            </svg>
                                        </span> */}
                                        <UsersIcon className="h-5 w-5" />
                                        <span className="mx-4 text-sm font-normal">
                                            Cell Leaders
                                        </span>
                                    </NavLink>
                                    }
                                    {userRole === 2 && <NavLink to="/dashboard/admin/reports" className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        {/* <span className="text-left">
                                            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1728 608v704q0 92-66 158t-158 66h-1216q-92 0-158-66t-66-158v-960q0-92 66-158t158-66h320q92 0 158 66t66 158v32h672q92 0 158 66t66 158z">
                                                </path>
                                            </svg>
                                        </span> */}
                                        <DocumentIcon className="h-5 w-5" />
                                        <span className="mx-4 text-sm font-normal">
                                            Admin Reports
                                        </span>
                                    </NavLink>
                                    }

                                    <NavLink to="/dashboard/settings" className="flex items-center justify-start w-full p-2 pl-6 my-2 text-gray-400 transition-colors duration-200 border-l-4 border-transparent hover:text-gray-800" href="#">
                                        <span className="text-left">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                                            </svg>
                                        </span>
                                        <span className="mx-4 text-sm font-normal">
                                            Settings
                                        </span>
                                    </NavLink>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:space-y-4">
                        {/* Top Nav bar */}
                        <header className="z-40 flex items-center justify-between w-full h-16">
                            {/* <div className="block ml-6 lg:hidden">
                                <button className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md"
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                                    <svg width="20" height="20" className="text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                                        </path>
                                    </svg>
                                </button>

                            </div> */}
                            <aside className={`flex h-auto w-1/2 flex-1 justify-start lg:hidden`}>
                                <h2 className="sr-only" id="mobile-navigation">Mobile navigation</h2>
                                <div className="flex items-center"><label id="close-button" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="relative z-[9999] cursor-pointer px-3 py-6" htmlFor="ss-mobile-menu">
                                    <input
                                        className="peer hidden" type="checkbox" id="ss-mobile-menu" />
                                    <div className="flex items-center p-2 text-gray-500 bg-white rounded-full shadow text-md">
                                        <svg width="20" height="20" className="text-gray-400" fill="currentColor" viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                                            </path>
                                        </svg>
                                    </div>
                                    <div className="fixed inset-0 z-[9999] hidden h-full w-full bg-heading/50 backdrop-blur-sm peer-checked:block">
                                        &nbsp;
                                    </div>
                                    <div
                                        className="fixed top-0 right-0 z-[9999] h-full w-full translate-x-full overflow-y-auto overscroll-y-none transition duration-500 peer-checked:translate-x-0 peer-checked:shadow-heading">
                                        <div className="float-right min-h-full w-[85%] bg-white px-6 pt-12 shadow-2xl">
                                            <menu className="mt-8 mb-8 flex flex-col space-y-4 text-heading">
                                                <li>{<NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/" className="mobile whitespace-nowrap pb-1 font-semibold peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Home</NavLink>}</li>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <li><a href="#" className="whitespace-nowrap pb-1 font-semibold">Cell</a>
                                                    <menu className="space-y-4 py-4 px-6 font-semibold">
                                                        <li>{userRole === 1 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/cellmembers" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Cell Members</NavLink>}</li>
                                                        <li>{userRole === 1 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/guests" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Guests</NavLink>}</li>
                                                        <li>{userRole === 2 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/cells" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Cells</NavLink>}</li>
                                                        <li>{userRole === 2 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/users" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Cell Leaders</NavLink>}</li>
                                                        <li>{userRole === 1 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/reports" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Reports</NavLink>}</li>
                                                        <li>{userRole === 2 && <NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/admin/reports" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Admin Reports</NavLink>}</li>
                                                    </menu>
                                                </li>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                <li><a href="#" className="whitespace-nowrap pb-1 font-semibold">User Management</a>
                                                    <menu className="space-y-4 py-4 px-6 font-semibold">
                                                        <li>{<NavLink onClick={() => { document.getElementById("close-button").click() }} to="/dashboard/settings" className="mobile whitespace-nowrap pb-1 peer-checked:translate-x-0 peer-checked:shadow-heading" href="#">Settings</NavLink>}</li>
                                                    </menu>
                                                </li>
                                                <li>{(
                                                    <button
                                                        className={`${true ? 'bg-red-600 text-white' : 'bg-red-300 text-red-600'
                                                            } font-bold group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                        onClick={() => logout()}
                                                    >
                                                        Logout
                                                    </button>
                                                )}</li>
                                            </menu>
                                        </div>
                                    </div>
                                </label></div>
                            </aside>


                            <div className="relative z-20 flex flex-col justify-end h-full px-3 md:w-full">
                                <div className="relative flex items-center justify-end w-full p-1 space-x-4">
                                    {/* <button className="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-gray-700 text-md">
                                        <svg width="20" height="20" className="" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
                                            </path>
                                        </svg>
                                    </button> */}
                                    {/* <button className="flex items-center p-2 text-gray-400 bg-white rounded-full shadow hover:text-gray-700 text-md">
                                        <svg width="20" height="20" className="text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M912 1696q0-16-16-16-59 0-101.5-42.5t-42.5-101.5q0-16-16-16t-16 16q0 73 51.5 124.5t124.5 51.5q16 0 16-16zm816-288q0 52-38 90t-90 38h-448q0 106-75 181t-181 75-181-75-75-181h-448q-52 0-90-38t-38-90q50-42 91-88t85-119.5 74.5-158.5 50-206 19.5-260q0-152 117-282.5t307-158.5q-8-19-8-39 0-40 28-68t68-28 68 28 28 68q0 20-8 39 190 28 307 158.5t117 282.5q0 139 19.5 260t50 206 74.5 158.5 85 119.5 91 88z">
                                            </path>
                                        </svg>
                                    </button> */}
                                    <span className="w-1 h-8 bg-gray-200 rounded-lg">
                                    </span>
                                    {/* <a href="#" className="relative block">
                                        <img alt="profil" src="/images/person/1.jpg" className="mx-auto object-cover rounded-full h-10 w-10 " />
                                    </a> */}
                                    {/* <button className="flex items-center text-gray-500 dark:text-white text-md" ref={btnDropdownRef}
                                        onClick={() => {
                                            dropdownPopoverShow
                                                ? closeDropdownPopover()
                                                : openDropdownPopover();
                                        }}>
                                        {name}
                                        <svg width="20" height="20" className="ml-2 text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z">
                                            </path>
                                        </svg>
                                    </button>

                                    <div
                                        ref={popoverDropdownRef}
                                        className={
                                            (dropdownPopoverShow ? "block " : "hidden ") +
                                            (color === "white" ? "bg-white " : bgColor + " ") +
                                            "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                                        }
                                        style={{ minWidth: "12rem" }}
                                    >
                                        <a
                                            href="#pablo"
                                            className={
                                                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                                                (color === "white" ? " text-slate-700" : "text-white")
                                            }
                                            onClick={e => e.preventDefault()}
                                        >
                                            Logout
                                        </a>
                                    </div> */}

                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-transparent bg-opacity-20 px-4 py-2 text-gray-500 dark:text-white text-md font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                {name}
                                                <svg width="20" height="20" className="ml-2 text-gray-400" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z">
                                                    </path>
                                                </svg>
                                                {/* <ChevronDownIcon
                                                    className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                                    aria-hidden="true"
                                                /> */}
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="px-1 py-1 ">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={`bg-red-300 text-red-600 font-bold group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={() => logout()}
                                                            >
                                                                Logout
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </header>
                        {/* Page content */}
                        <Outlet />
                    </div>
                    {/* {isMobileMenuOpen && ( */}
                    {/* <nav className="fixed inset-0 flex flex-col justify-center items-center bg-gray-100"> */}
                    {/* Add your mobile menu items here */}
                    {/* <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard" className="text-gray-800 font-bold text-lg mb-4">Dashboard</NavLink>
                            <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard/guests" className="text-gray-800 font-bold text-lg mb-4">Guests</NavLink>
                            <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard/reports" className="text-gray-800 font-bold text-lg mb-4">Reports</NavLink>
                            <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard/users" className="text-gray-800 font-bold text-lg mb-4">Cell Leaders</NavLink>
                            <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard/admin/reports" className="text-gray-800 font-bold text-lg mb-4">Admin Reports</NavLink>
                            <NavLink onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} to="/dashboard/settings" className="text-gray-800 font-bold text-lg mb-4">Settings</NavLink> */}
                    {/* </nav> */}
                    {/* )} */}
                </div>
                <Footer />
            </main >

        </div >
    )
}

Dashboard.propTypes = {
    user: PropTypes.object.isRequired
}

Dashboard.defaultProps = {
    user: {}
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    onLogoutRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);