import {
    GET_GUESTS_REQUESTED,
    GET_GUESTS_SUCCESS,
    GET_GUESTS_FAILED,
    ADD_GUEST_REQUESTED,
    ADD_GUEST_SUCCESS,
    ADD_GUEST_FAILED,
    UPDATE_GUEST_REQUESTED,
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_FAILED,
    DELETE_GUEST_REQUESTED,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_FAILED,
} from './types';

export const getGuestsRequested = (data) => ({ type: GET_GUESTS_REQUESTED, payload: data });
export const getGuestsSuccess = (data) => ({ type: GET_GUESTS_SUCCESS, payload: data });
export const getGuestsFailed = (data) => ({ type: GET_GUESTS_FAILED, payload: data });

export const addGuestRequested = (data) => ({ type: ADD_GUEST_REQUESTED, payload: data });
export const addGuestSuccess = (data) => ({ type: ADD_GUEST_SUCCESS, payload: data });
export const addGuestFailed = (data) => ({ type: ADD_GUEST_FAILED, payload: data });

export const updateGuestRequested = (data) => ({ type: UPDATE_GUEST_REQUESTED, payload: data });
export const updateGuestSuccess = (data) => ({ type: UPDATE_GUEST_SUCCESS, payload: data });
export const updateGuestFailed = (data) => ({ type: UPDATE_GUEST_FAILED, payload: data });

export const deleteGuestRequested = (id) => ({ type: DELETE_GUEST_REQUESTED, payload: id });
export const deleteGuestSuccess = (data) => ({ type: DELETE_GUEST_SUCCESS, payload: data });
export const deleteGuestFailed = (data) => ({ type: DELETE_GUEST_FAILED, payload: data });
