import {all} from 'redux-saga/effects';
import userSaga from './userSaga';
import cellSaga from './cellSaga';
import cellMemberSaga from './cellMemberSaga';
import cellLeaderSaga from './cellLeaderSaga';
import guestSaga from './guestSaga';
import reportSaga from './reportSaga';
import authSaga from './authSaga';

// saga function that has the ability to run 
// other saga functions in parallel
export default function* rootSaga() {
    yield all([
        userSaga(),
        cellSaga(),
        cellLeaderSaga(),
        cellMemberSaga(),
        guestSaga(),
        reportSaga(),
        authSaga(),
    ]);
}