import {
    GET_CELL_MEMBERS_REQUESTED,
    GET_CELL_MEMBERS_SUCCESS,
    GET_CELL_MEMBERS_FAILED,
    GET_CELL_MEMBER_BY_ID_REQUESTED,
    GET_CELL_MEMBER_BY_ID_SUCCESS,
    GET_CELL_MEMBER_BY_ID_FAILED,
    ADD_CELL_MEMBER_REQUESTED,
    ADD_CELL_MEMBER_SUCCESS,
    ADD_CELL_MEMBER_FAILED,
    UPDATE_CELL_MEMBER_REQUESTED,
    UPDATE_CELL_MEMBER_SUCCESS,
    UPDATE_CELL_MEMBER_FAILED,
    DELETE_CELL_MEMBER_REQUESTED,
    DELETE_CELL_MEMBER_SUCCESS,
    DELETE_CELL_MEMBER_FAILED,

} from './types';

export const getCellMembersRequested = (data) => ({ type: GET_CELL_MEMBERS_REQUESTED, payload: data });
export const getCellMembersSuccess = (data) => ({ type: GET_CELL_MEMBERS_SUCCESS, payload: data });
export const getCellMembersFailed = (data) => ({ type: GET_CELL_MEMBERS_FAILED, payload: data });

export const getCellMemberByIdRequested = () => ({ type: GET_CELL_MEMBER_BY_ID_REQUESTED });
export const getCellMemberByIdSuccess = (data) => ({ type: GET_CELL_MEMBER_BY_ID_SUCCESS, payload: data });
export const getCellMemberByIdFailed = (data) => ({ type: GET_CELL_MEMBER_BY_ID_FAILED, payload: data });

export const addCellMemberRequested = ({id,data}) => ({ type: ADD_CELL_MEMBER_REQUESTED,  payload: {id,data} });
export const addCellMemberSuccess = (data) => ({ type: ADD_CELL_MEMBER_SUCCESS, payload: data });
export const addCellMemberFailed = (data) => ({ type: ADD_CELL_MEMBER_FAILED, payload: data });

export const updateCellMemberRequested = ({cellId, id, data}) => ({ type: UPDATE_CELL_MEMBER_REQUESTED, payload: {cellId, id, data} });
export const updateCellMemberSuccess = (data) => ({ type: UPDATE_CELL_MEMBER_SUCCESS, payload: data });
export const updateCellMemberFailed = (data) => ({ type: UPDATE_CELL_MEMBER_FAILED, payload: data });

export const deleteCellMemberRequested = (id) => ({ type: DELETE_CELL_MEMBER_REQUESTED, payload: id });
export const deleteCellMemberSuccess = (data) => ({ type: DELETE_CELL_MEMBER_SUCCESS, payload: data });
export const deleteCellMemberFailed = (data) => ({ type: DELETE_CELL_MEMBER_FAILED, payload: data });
