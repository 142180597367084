import {
    GET_ADMIN_REPORT_REQUESTED,
    GET_ADMIN_REPORT_SUCCESS,
    GET_ADMIN_REPORT_FAILED,
    GET_REPORTS_REQUESTED,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAILED,
    GET_REPORT_BY_ID_REQUESTED,
    GET_REPORT_BY_ID_SUCCESS,
    GET_REPORT_BY_ID_FAILED,
    GET_REPORT_DETAILS_REQUESTED,
    GET_REPORT_DETAILS_SUCCESS,
    GET_REPORT_DETAILS_FAILED,
    ADD_REPORT_REQUESTED,
    ADD_REPORT_SUCCESS,
    ADD_REPORT_FAILED,
    UPDATE_REPORT_REQUESTED,
    UPDATE_REPORT_SUCCESS,
    UPDATE_REPORT_FAILED,
    DELETE_REPORT_REQUESTED,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_FAILED,
    UPDATE_ATTENDANCE,
    UPDATE_GUEST_ATTENDANCE,
    UPDATE_TESTIMONIES,
    UPDATE_EVALUATIONS,
    UPDATE_DATE,
    SUBMIT_NEW_REPORT_REQUESTED,
    SUBMIT_NEW_REPORT_SUCCESS,
    SUBMIT_NEW_REPORT_FAILED,
    CLEAR_REPORTS,
} from '../actions/types';

const initialState = {
    report: null,
    cellMembers: [],
    guests: [],
    reports: [],
    error: null,
    loading: false,
    success: false,
    adminReportGetRequested: false,
    adminReportGetError: '',
    adminReportGetSuccess: false,
    reportGetRequested: false,
    reportGetError: '',
    reportGetSuccess: false,
    reportDetailsRequested: false,
    reportDetailsError: '',
    reportDetailsSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ADMIN_REPORT_REQUESTED:
            return {
                ...state,
                loading: true,
                adminReportGetRequested: true,
            };
        case GET_ADMIN_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                adminReportGetRequested: false,
                adminReportGetSuccess: true,
                reports: action.payload,
            };

        case GET_ADMIN_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_REPORTS_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GET_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                reports: action.payload,
                cellMembers: [],
                guests: [],
            };
        case GET_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_REPORT_BY_ID_REQUESTED:
            return {
                ...state,
                loading: true,
                reportGetRequested: true,
            };
        case GET_REPORT_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                reportGetRequested: false,
                report: action.payload,
                guests: action.payload.GuestsAttendance,
                cellMembers: action.payload.CellMemberAttendance,
            };
        case GET_REPORT_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_REPORT_DETAILS_REQUESTED:
            return {
                ...state,
                loading: true,
                reportDetailsRequested: true,
            };
        case GET_REPORT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                reportDetailsRequested: false,
                report: action.payload,
                guests: action.payload.Guest,
                cellMembers: action.payload.cellMember,
            };
        case GET_REPORT_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SUBMIT_NEW_REPORT_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case SUBMIT_NEW_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                report: action.payload,
            };
        case SUBMIT_NEW_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case ADD_REPORT_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case ADD_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                report: action.payload,
                reports: [...state.reports, action.payload],
            };
        case ADD_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_REPORT_REQUESTED:
            return {
                ...state,
                loading: true
            };
        case UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                reports: state.reports.map((report) => {
                    if (report.id === action.payload.id) {
                        return action.payload;
                    }
                    return report;
                }),
            };
        case UPDATE_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_REPORT_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case DELETE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                reports: state.reports.filter((report) => report.id !== action.payload.id),
            };
        case DELETE_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAR_REPORTS:
            return {
                ...state,
                report: null,
                reports: [],
                error: null,
                loading: false,
                success: false,
                adminReportGetRequested: false,
                adminReportGetError: '',
                adminReportGetSuccess: false,
                reportGetRequested: false,
                reportGetError: '',
                reportGetSuccess: false,
            };
        case UPDATE_ATTENDANCE:
            return {
                ...state,
                cellMembers: state.cellMembers.map((member) => {
                    if (member.id === action.payload.id || member.member_id === action.payload.id) {
                        return {...member, 'attendance': action.payload.value};
                    }
                    return member;
                }),
            };
        case UPDATE_GUEST_ATTENDANCE:
            return {
                ...state,
                guests: state.guests.map((guest) => {
                    if (guest.id === action.payload.id || guest.guest_id === action.payload.id) {
                        return {...guest, 'attendance': action.payload.value};
                    }
                    return guest;
                }),
            };
        case UPDATE_TESTIMONIES:
            return {
                ...state,
                report: {...state.report, 'testimonies': action.payload},
            };
        case UPDATE_EVALUATIONS:
            return {
                ...state,
                report: {...state.report, 'evaluations': action.payload},
            };
        case UPDATE_DATE:
            return {
                ...state,
                report: {...state.report, 'date': action.payload},
            };
        default:
            return state;
    }
};
