import { React, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { onLoginRequested } from "../../actions/authAction";
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types';
import { bindActionCreators } from "@reduxjs/toolkit";
import Logo from "../../assets/cellies.png";

const Login = ({ error, onLoginRequested: login }) => {
    //states
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    //delete localstorage
    // useEffect(() => {
    //     localStorage.removeItem("user");
    //     localStorage.removeItem("token");
    // }, []);



    //Redux states
    // const { loading } = useSelector((state) => state.user);
    const loading = false;

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    const handleLogin = (e) => {
        e.preventDefault();
        let data = { email, password }
        // console.log(data);
        login(data);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Error:</strong>
                    <span className="block sm:inline">{error}</span>
                </div>}
                <form className="space-y-6" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address/username</label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="email or username"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                            <div className="text-sm">
                                <a href="#" className="font-semibold text-anfgc hover:text-indigo-500">Forgot password?</a>
                            </div>
                        </div>
                        <div className="mt-2 relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="current-password"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                onClick={toggleShowPassword}
                            >
                                {showPassword ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                            </button>
                        </div>
                    </div>

                    <div>
                        <button type="submit"
                            className="flex w-full justify-center rounded-md bg-anfgc px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            {loading ? 'Loading...' : 'Sign in'}
                        </button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?
                    <a href="#" className="font-semibold leading-6 text-anfgc hover:text-indigo-900">Ask your admin to create an account for you 😁 </a>
                </p>
            </div>
        </div>
    );
}

Login.propTypes = {
    error: PropTypes.string,
    onLoginRequested: PropTypes.func.isRequired
};

Login.defaultProps = {
    error: ''
};

const mapStateToProps = (state) => ({
    error: state.auth.loginError
});

const mapDispatchToProps = dispatch => bindActionCreators({
    onLoginRequested
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);