import {
    GET_GUESTS_REQUESTED,
    GET_GUESTS_SUCCESS,
    GET_GUESTS_FAILED,
    ADD_GUEST_REQUESTED,
    ADD_GUEST_SUCCESS,
    ADD_GUEST_FAILED,
    UPDATE_GUEST_REQUESTED,
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_FAILED,
    DELETE_GUEST_REQUESTED,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_FAILED,
} from '../actions/types';

import {
    getGuestsRequested,
    getGuestsSuccess,
    getGuestsFailed,
    addGuestRequested,
    addGuestSuccess,
    addGuestFailed,
    updateGuestRequested,
    updateGuestSuccess,
    updateGuestFailed,
    deleteGuestRequested,
    deleteGuestSuccess,
    deleteGuestFailed,
} from '../actions/guestAction';

/* ----- Saga Effects  ----- */
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import API from '../utils/api';

/* ----- GET GUESTS ----- */
export function* getGuests({payload}) {
    try {
        const response = yield call(API.getData, `cells/${payload}/guests`);
        yield put(getGuestsSuccess(response));
    } catch (error) {
        yield put(getGuestsFailed(error));
    }
}

export function* addGuest({payload}) {
    try {
        const response = yield call(API.postData, `cells/${payload.cellId}/guests`, payload.data);
        yield put(addGuestSuccess(response));
    } catch (error) {
        yield put(addGuestFailed(error));
    }
}

export function* updateGuest({payload}) {
    try {
        const response = yield call(API.updateData, `cells/${payload.cellId}/guests/${payload.id}`, payload.data);
        yield put(updateGuestSuccess(response));
    } catch (error) {
        yield put(updateGuestFailed(error));
    }
}

export function* deleteGuest({payload}) {
    try {
        const response = yield call(API.deleteData, `cells/${payload.cellId}/guests/${payload.id}`);
        yield put(deleteGuestSuccess({...response, id: payload.id}));
    } catch (error) {
        yield put(deleteGuestFailed(error));
    }
}

export default function* guestSaga() {
    yield takeEvery(GET_GUESTS_REQUESTED, getGuests);
    yield takeEvery(ADD_GUEST_REQUESTED, addGuest);
    yield takeEvery(UPDATE_GUEST_REQUESTED, updateGuest);
    yield takeEvery(DELETE_GUEST_REQUESTED, deleteGuest);
}