import {
    GET_GUESTS_REQUESTED,
    GET_GUESTS_SUCCESS,
    GET_GUESTS_FAILED,
    ADD_GUEST_REQUESTED,
    ADD_GUEST_SUCCESS,
    ADD_GUEST_FAILED,
    UPDATE_GUEST_REQUESTED,
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_FAILED,
    DELETE_GUEST_REQUESTED,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_FAILED,
} from '../actions/types';

const initialState = {
    guests: [],
    guest: {},
    error: null,
    loading: false,
    success: false,

    guestsGetRequested: false,
    guestsGetError: '',
    guestsGetSuccess: false,

    guestGetRequested: false,
    guestGetError: '',
    guestGetSuccess: false,

    guestAddRequested: false,
    guestAddError: '',
    guestAddSuccess: false,

    guestUpdateRequested: false,
    guestUpdateError: '',
    guestUpdateSuccess: false,

    guestDeleteRequested: false,
    guestDeleteError: '',
    guestDeleteSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GUESTS_REQUESTED:
            return {
                ...state,
                guestsGetRequested: true,
                guestsGetError: '',
                guestsGetSuccess: false,
            };
        case GET_GUESTS_SUCCESS:
            return {
                ...state,
                guestsGetRequested: false,
                guestsGetSuccess: true,
                guests: action.payload,
            };
        case GET_GUESTS_FAILED:
            return {
                ...state,
                guestsGetRequested: false,
                guestsGetError: action.payload,
            };
        case ADD_GUEST_REQUESTED:
            return {
                ...state,
                guestAddRequested: true,
                guestAddError: '',
                guestAddSuccess: false,
            };
        case ADD_GUEST_SUCCESS:
            return {
                ...state,
                guestAddRequested: false,
                guestAddSuccess: true,
                guests: [...state.guests, action.payload],
            };
        case ADD_GUEST_FAILED:
            return {
                ...state,
                guestAddRequested: false,
                guestAddError: action.payload,
            };
        case UPDATE_GUEST_REQUESTED:
            return {
                ...state,
                guestUpdateRequested: true,
                guestUpdateError: '',
                guestUpdateSuccess: false,
            };
        case UPDATE_GUEST_SUCCESS:
            return {
                ...state,
                guestUpdateRequested: false,
                guestUpdateSuccess: true,
                guest: action.payload,
            };
        case UPDATE_GUEST_FAILED:
            return {
                ...state,
                guestUpdateRequested: false,
                guestUpdateError: action.payload,
            };
        case DELETE_GUEST_REQUESTED:
            return {
                ...state,
                guestDeleteRequested: true,
                guestDeleteError: '',
                guestDeleteSuccess: false,
            };
        case DELETE_GUEST_SUCCESS:
            return {
                ...state,
                guestDeleteRequested: false,
                guestDeleteSuccess: true,
                guests: state.guests.filter((guest) => guest.id !== action.payload.id),
            };
        case DELETE_GUEST_FAILED:
            return {
                ...state,
                guestDeleteRequested: false,
                guestDeleteError: action.payload,
            };
        default:
            return state;
    }
};