import {
    ON_LOAD_LOGIN_FAILED,
    ON_LOAD_LOGIN_REQUESTED,
    ON_LOAD_LOGIN_SUCCESS,
    POST_RESET_PASSWORD_FAILED,
    POST_RESET_PASSWORD_REQUESTED,
    POST_RESET_PASSWORD_SUCCESS,
    LOGOUT_FAILED,
    LOGOUT_REQUESTED,
    LOGOUT_SUCCESS,
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    loginRequested: false,
    loginFailed: false,
    loginError: '',

    resetPasswordRequested: false,
    resetPasswordError: '',
    resetPasswordSuccess: false,

    logoutRequested: false,
    logoutError: '',
    logoutSuccess: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ON_LOAD_LOGIN_REQUESTED:
            return {
                ...state,
                loginRequested: true,
            };
        case ON_LOAD_LOGIN_SUCCESS:
            return {
                ...state,
                loginRequested: false,
                isAuthenticated: true,
            };
        case ON_LOAD_LOGIN_FAILED:
            return {
                ...state,
                loginFailed: true,
                loginRequested: false,
                loginError: action.payload,
            };
        case POST_RESET_PASSWORD_REQUESTED:
            return {
                ...state,
                resetPasswordRequested: true,
            };
        case POST_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordRequested: false,
                resetPasswordSuccess: true,
            };
        case POST_RESET_PASSWORD_FAILED:
            return {
                ...state,
                resetPasswordRequested: false,
                resetPasswordError: action.payload,
            };
        case LOGOUT_REQUESTED:
            return {
                ...state,
                logoutRequested: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logoutRequested: false,
                isAuthenticated: false,
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                logoutRequested: false,
                logoutError: action.payload,
            };
        default:
            return state;
    }            
}