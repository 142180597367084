import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { MaterialReactTable } from 'material-react-table';
// import { Report } from "../../reducers/reportReducer";
import Table, { AvatarCell, SelectColumnFilter, StatusPill, ActionsPill } from '../../components/Table'  // new
// import { GetCellMembers, AddCellMember } from '../../reducers/cellMemberReducer';
import Modal from '../../components/Modal';
import { getReportsRequested, addReportRequested, deleteReportRequested } from "../../actions/reportAction";
import { useDispatch, connect } from "react-redux";
import { TrashIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';

const Report = ({
    // cellMembers,
    reportsGetRequested,
    reports,
    getReportsRequested: getReport,
    // addReportRequested: addReport,
    deleteReportRequested: deleteReport,
}) => {
    // const [report, setReport] = useState(null);
    const [showModal, setShowModal] = React.useState(false);
    // const [cellMembers, setCellMembers] = React.useState(null);
    // const [error, setError] = useState(null);
    // const [loading, setLoading] = useState(false);
    // const [formData, setFormData] = React.useState({});
    // const id = 1;
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cells } = JSON.parse(localStorage.getItem('user'));
    const cellId = cells[0]?.id;

    useEffect(() => {
        if (!reportsGetRequested) {
            getReport(cellId);
        }
    }, [reportsGetRequested,cellId]);

    const handleCreateReport = () => {
        console.log("Add member");
        // setShowModal(true);
        navigate(`/dashboard/reports/create/${cellId}`, { state: { cellId, data: {} } });
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        // setFormData({});
        setShowModal(false);
    };

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleDeleteReport = (data_id) => {
        console.log("Delete Report");
        const confirmDelete = window.confirm("Are you sure you want to delete this report?");
        // console.log(formData);
        if (!confirmDelete) return;
        notify("Deleting report...");
        deleteReport({ cellId, id: data_id });
        // setShowModal(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // setFormData(values => ({ ...values, [name]: value }))
    }

    const columns = React.useMemo(() => [
        {
            Header: "#",
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-4">
                    <p>{cell.row.index + 1}</p>
                </div>
            ),
        },
        {
            Header: "Date",
            accessor: 'date',
            Cell: ({ cell }) => (
                <div className="text-sm text-gray-500">
                    {moment(cell.row.original.date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                </div>
            ),
        },
        {
            Header: "Total Members",
            accessor: 'cellTotal',
        },
        {
            Header: "Total Present",
            accessor: 'attendanceTotal',
        },
        {
            Header: "No. Guests",
            accessor: 'guestsTotal',
        },
        {
            Header: "Actions",
            accessor: 'status',
            idAccessor: 'id',
            // Cell: ActionsPill,
            Cell: ({ cell, row, column }) => (
                <div className="flex items-center space-x-1">
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-yellow-100 text-yellow-800"
                        onClick={() => navigate(`/dashboard/reports/edit/${cellId}/${cell.row.original.id}`)}
                    >
                        Edit
                    </button>
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-red-100 text-red-800"
                        onClick={() => handleDeleteReport(row.original[column.idAccessor])}
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </div>
            ),
        },
    ], [])

    return (
        <div className="h-400 bg-gray-100 text-gray-900">
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-between content-between">
                    <h1 className="text-xl font-semibold">Cell Reports</h1>
                    {cellId && (
                        <button
                            onClick={() => handleCreateReport()}
                            className="text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                        >
                            <i className="fas fa-plus"></i>
                            Create Report
                        </button>
                    )}
                </div>
                <div className="mt-6">
                    {reports && reports.length > 0 && <Table columns={columns} data={reports} />}
                    {reports && reports.length === 0 && (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">No reports found</h1>
                            <p className="text-sm text-gray-500">Add a new report</p>
                        </div>
                    )}
                    {/* <Table columns={columns} data={data} /> */}
                </div>
                {cellId === undefined && (
                    <div className="mt-6">
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">This account isn't assinged to a cell yet!</h1>
                            <p className="text-sm text-gray-500">Contact admin.</p>
                        </div>
                    </div>
                )}
            </main>
        </div>
    );
}


Report.propTypes = {
    reports: PropTypes.array,
    reportsGetRequested: PropTypes.bool,
    getReportsRequested: PropTypes.func,
    addReportRequested: PropTypes.func,
};

Report.defaultProps = {
    reports: [],
    reportsGetRequested: false,
    reportGetRequested: false,
};

const mapStateToProps = (state) => ({
    reports: state.report.reports,
    reportsGetRequested: state.report.reportsGetRequested,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getReportsRequested,
    addReportRequested,
    deleteReportRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Report);