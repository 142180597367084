function Footer() {
    return (

        <footer className="self-end px-3 py-8 text-gray-500 transition-colors duration-200 bg-white dark:bg-gray-700 text-2 dark:text-gray-200 pt-8">
            <div className="flex flex-col">
                <div className="h-px mx-auto rounded-full md:hidden mt-0 w-11">
                </div>
                <div className="flex flex-col mt-4 md:mt-0 md:flex-row">
                    <nav className="flex flex-col items-center justify-center flex-1 border-gray-100 md:items-end md:border-r md:pr-5">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a aria-current="page" href="#" className="hover:text-gray-700 dark:hover:text-white">
                            ANFGC Halifax
                        </a>
                        {/* <a aria-current="page" href="#" className="hover:text-gray-700 dark:hover:text-white">
                            ANFGC Toronto
                        </a> */}
                    </nav>
                    {/* <div className="h-px mx-auto mt-4 rounded-full md:hidden w-11">
                    </div>
                    <div className="flex items-center justify-center flex-1 mt-4 border-gray-100 md:mt-0 md:border-r">
                        
                    </div>
                    <div className="h-px mx-auto mt-4 rounded-full md:hidden w-11 ">
                    </div> */}
                    <div className="flex flex-col items-center justify-center flex-1 mt-2 md:mt-0 md:items-start md:pl-5">
                        <span className="">
                            © {new Date().getFullYear()} 
                        </span>
                        <span className="mt-2 md:mt-1">
                            {/* Created with ❤ */}
                        </span>
                        <p className="mt-2 md:mt-1">
                        To train and raise leaders for the body of Christ.
                        </p>
                        <p className="mt-2 md:mt-1">2 Timothy 2:2</p>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;