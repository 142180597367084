import {
    GET_USER_REQUESTED,
    UPDATE_USER_REQUESTED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_PASSWORD_REQUESTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,
} from '../actions/types';

import { takeLatest, put, call } from 'redux-saga/effects';
import Api from '../utils/api';

import {
    getUserSuccess,
    getUserFailed,
    updateUserSuccess,
    updateUserFailed,
    updatePasswordSuccess,
    updatePasswordFailed,
} from '../actions/userAction';

function* getUser({id}) {
    try {
        const response = yield call(Api.getData, `users/${id}`);
        yield put(getUserSuccess(response));
    } catch (error) {
        yield put(getUserFailed(error));
    }
}

function* updateUser(action) {
    try {
        const response = yield call(Api.putData, `users/`, action.payload);
        yield put(updateUserSuccess(response));
    } catch (error) {
        yield put(updateUserFailed(error));
    }
}

function* updatePassword(action) {
    try {
        const response = yield call(Api.putData, "users/password", action.payload);
        yield put(updatePasswordSuccess(response));
    } catch (error) {
        yield put(updatePasswordFailed(error));
    }
}

export default function* userSaga() {
    yield takeLatest(GET_USER_REQUESTED, getUser);
    yield takeLatest(UPDATE_USER_REQUESTED, updateUser);
    yield takeLatest(UPDATE_PASSWORD_REQUESTED, updatePassword);
}
