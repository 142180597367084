import React, { useMemo, useState, useEffect } from 'react'
import Table from './../../../components/Table'  // new
import { connect } from 'react-redux'
import Modal from '../../../components/Modal';
import { getCellsRequested, addCellRequested, updateCellRequested, deleteCellRequested } from "../../../actions/cellAction";
import { getCellLeadersRequested } from "../../../actions/cellLeaderAction";
import PropTypes from 'prop-types';
import { TrashIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

const Cell = ({
    user,
    cellsGetRequested,
    cells,
    getCellsRequested: getCell,
    addCellRequested: addCell,
    updateCellRequested: updateCell,
    deleteCellRequested: deleteCell,
    getCellLeadersRequested: getCellLeaders,
    cellLeaders,
    cellLeaderGetRequested }) => {
    const [showModal, setShowModal] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!cellsGetRequested) {
            getCell()
        }
    }, [cellsGetRequested]);

    useEffect(() => {
        if (!cellLeaderGetRequested) {
            getCellLeaders()
        }
    }, [cellLeaderGetRequested]);

    const columns = React.useMemo(() => [
        {
            Header: "#",
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-4">
                    <p>{cell.row.index + 1}</p>
                </div>
            ),
        },
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Address",
            accessor: 'address',
        },
        {
            Header: "Cell Leader",
            accessor: 'CellLeader.name',
        },
        {
            Header: "Meeting Day",
            accessor: 'meeting_day',
            // Cell: StatusPill,
        },
        {
            Header: "Meeting Time",
            accessor: 'meeting_time',
            // Cell: StatusPill,
        },
        {
            Header: "Action",
            accessor: 'id',
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleEditCell(cell.row.original)}
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-yellow-100 text-yellow-800"
                    >Edit</button>
                    <button
                        onClick={() => handleDeleteCell(cell.row.original.id)}
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-red-100 text-red-800"
                    ><TrashIcon className="h-5 w-5" /></button>
                </div>
            ),
        }
    ], [])

    if (!user || !user.id) {
        navigate('/login');
        return;
    }

    const notify = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    const handleAddCell = () => {
        console.log("Add cell");
        setShowModal(true);
    }

    const handleEditCell = (data) => {
        console.log("Edit cell");
        setEdit(true);
        setFormData(data);
        setShowModal(true);
    }

    const handleDeleteCell = (data) => {
        console.log("Delete cell");
        console.log(data);
        const confirmDelete = window.confirm("Are you sure you want to delete this cell?");
        // console.log(formData);
        if (!confirmDelete) return;
        notify("Deleting Cell...");
        deleteCell(data);
    }

    const handleCloseModal = (e) => {
        e.preventDefault();
        setFormData({});
        setEdit(false);
        setShowModal(false);
    };

    const handleSaveCell = (e) => {
        e.preventDefault();
        console.log("Save Cell");
        console.log(formData);
        addCell(formData);
        notify("Cell created successfully!");
        setShowModal(false);
    };

    const handleUpdateCell = (e) => {
        e.preventDefault();
        console.log("Edit cell");
        console.log(formData);
        updateCell(formData);
        setShowModal(false);
        setEdit(false);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    // console.log(`Member data ${cellMembers}`)
    // const data = React.useMemo(() => cellMembers, [])

    return (
        <div className="h-400 bg-gray-100 text-gray-900">

            {/* {showModal&& (<p>Mom, mm dal</p>)} */}
            <Modal title={edit ? 'Edit cell' : 'Create a new cell'} onClose={handleCloseModal} show={showModal} data={formData} onSubmit={edit && edit ? handleUpdateCell : handleSaveCell}>
                <form className='space-y-6'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="cell name"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.name || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">Address</label>
                        <div className="mt-2">
                            <input
                                id="address"
                                name="address"
                                type="text"
                                autoComplete="Cell Location"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.address || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="cell_leader" className="block text-sm font-medium leading-6 text-gray-900">Cell Leader</label>
                        <div className="mt-2">
                            <select className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                name="cell_leader"
                                value={formData?.cell_leader || ""}
                                onChange={handleChange}>
                                <option value="">
                                    Select a cell leader
                                </option>
                                {cellLeaders && cellLeaders.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="meeting_day" className="block text-sm font-medium leading-6 text-gray-900">Meeting Day</label>
                        <div className="mt-2">
                            <select className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                name="meeting_day"
                                value={formData?.meeting_day || ""}
                                onChange={handleChange}>
                                <option value="">
                                    Select an option
                                </option>
                                <option value="Monday">
                                    Monday
                                </option>
                                <option value="Tuesday">
                                    Tuesday
                                </option>
                                <option value="Thurday">
                                    Thursday
                                </option>
                                <option value="Saturday">
                                    Saturday
                                </option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="cell_type" className="block text-sm font-medium leading-6 text-gray-900">Cell Type</label>
                        <div className="mt-2">
                            <select className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                name="cell_type"
                                value={formData?.cell_type || ""}
                                onChange={handleChange}>
                                <option value="">
                                    Select an option
                                </option>
                                <option value="Campus Cell">
                                    Campus Cell
                                </option>
                                <option value="Home Cell">
                                    Home Cell
                                </option>
                                <option value="Family Cell">
                                    Family Cell
                                </option>
                                <option value="Inactive Cell">
                                    Inactive Cell
                                </option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="meeting_time" className="block text-sm font-medium leading-6 text-gray-900">Meeting Time</label>
                        <div className="mt-2">
                            <input className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                autoComplete="Cell Location"
                                required
                                name="meeting_time"
                                type="time"
                                value={formData?.meeting_time || ""}
                                onChange={handleChange} />

                        </div>
                    </div>
                </form>
            </Modal>
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-between content-between">
                    <h1 className="text-xl font-semibold">Cells</h1>
                    <button
                        onClick={() => handleAddCell()}
                        className="flex flex-row items-center justify-between text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        {/* <i className="fas fa-plus"></i> */}
                        <PlusCircleIcon className="h-4 w-4" />
                        Add Cell
                    </button>
                </div>
                <div className="mt-6">
                    {cells.length > 0 && (<Table columns={columns} data={cells} />)}
                    {cells.length === 0 && (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">No cells found</h1>
                            <p className="text-sm text-gray-500">Add a new cell</p>
                        </div>
                    )}
                    {/* <Table columns={columns} data={data} /> */}
                </div>
            </main>
        </div>
    );
}

Cell.propTypes = {
    cells: PropTypes.array,
    cellsGetRequested: PropTypes.bool,
    cellLeaderGetRequested: PropTypes.bool,
    cellLeaders: PropTypes.array,
    getCellsRequested: PropTypes.func.isRequired,
    addCellRequested: PropTypes.func.isRequired,
    deleteCellRequested: PropTypes.func.isRequired,
    updateCellRequested: PropTypes.func.isRequired,
    getCellLeadersRequested: PropTypes.func.isRequired
};

Cell.defaultProps = {
    cells: [],
    cellsGetRequested: false,
    cellLeaderGetRequested: false,
    cellLeaders: []
};

const mapStateToProps = (state) => ({
    cells: state.cell.cells,
    cellLeaders: state.cellLeader.cellLeaders,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCellsRequested,
    getCellLeadersRequested,
    addCellRequested,
    updateCellRequested,
    deleteCellRequested
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cell);