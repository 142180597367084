import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './views/Login';
import Home from "./views/Home";
import Dashboard from "./views/Dashboard";
import DashboardHome from "./views/DashboardHome";
import CellMembers from "./views/Dashboard/CellMembers";
import Cell from "./views/Dashboard/Cell";
import Users from "./views/Users";
import Reports from "./views/Reports";
import Guests from "./views/Dashboard/Guests";
import AddReport from "./views/Reports/AddReport";
import EditReport from "./views/Reports/EditReport";
import AdminReport from "./views/Reports/AdminReport";
import Settings from "./views/Settings";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onLoginRequested } from './actions/authAction';
import './App.css';

const App = ({loggedIn, onLoginRequested, user}) => {
  const currUser = JSON.parse(localStorage.getItem('user'));

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard/" element={<Dashboard user={currUser} />}>
          <Route index element={<DashboardHome user={currUser} />} />
          <Route path="settings" element={<Settings user={currUser}  />} />
          <Route path="reports" element={<Reports user={currUser} />} />
          <Route path="reports/edit/:cellId/:id" element={<EditReport user={currUser} />} />
          <Route path="reports/create/:cellId" element={<AddReport user={currUser} />} />
          <Route path="admin/reports" element={<AdminReport user={currUser}/>} />
          <Route path="cells" element={<Cell user={currUser} />} />
          <Route path="cellmembers" element={<CellMembers user={currUser} />} />
          <Route path="guests" element={<Guests user={currUser} />} />
          <Route path="users" element={<Users user={currUser} />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Route>
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

App.propTypes ={
  loggedIn: PropTypes.bool,
  user: PropTypes.object,
  onLoginRequested: PropTypes.func.isRequired
}

App.defaultProps = {
  loggedIn: false,
};

const mapStateToProps = state => ({
  loggedIn: state.auth.isAuthenticated,
  user: state.user.user
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onLoginRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
