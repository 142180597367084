import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    POST_RESET_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAILED,
    ON_LOAD_LOGIN_REQUESTED,
    ON_LOAD_LOGIN_SUCCESS,
    ON_LOAD_LOGIN_FAILED,
    LOGOUT_REQUESTED,
    POST_RESET_PASSWORD_REQUESTED,
} from './types';
import { history } from '../utils/history';

/* ----- LOGIN ----- */
export const onLoginRequested = (data) => ({ type: ON_LOAD_LOGIN_REQUESTED, payload: data });
export const onLoginSuccess = (data) => {
    // history.push('/');
    console.log('data', data);
    window.location.assign('/dashboard');
    return ({ type: ON_LOAD_LOGIN_SUCCESS, payload: data });
};
export const onLoginFailed = (data) => ({ type: ON_LOAD_LOGIN_FAILED, payload: data });

/* ----- LOGOUT ----- */
export const onLogoutRequested = () => {
    // window.location.assign('/login');
    return ({ type: LOGOUT_REQUESTED });
};

export const onLogoutSuccess = () => {
    window.location.assign('/login');
    return ({ type: LOGOUT_SUCCESS })
};
export const onLogoutFailed = () => ({ type: LOGOUT_FAILED });

/* ----- RESET PASSWORD ----- */
export const onResetPasswordRequested = (data) => ({ type: POST_RESET_PASSWORD_REQUESTED, payload: data });
export const onResetPasswordSuccess = (data) => ({ type: POST_RESET_PASSWORD_SUCCESS, payload: data });
export const onResetPasswordFailed = (data) => ({ type: POST_RESET_PASSWORD_FAILED, payload: data });

