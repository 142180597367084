import {
    GET_CELLS_REQUESTED,
    GET_CELLS_SUCCESS,
    GET_CELLS_FAILED,
    GET_CELL_BY_ID_REQUESTED,
    GET_CELL_BY_ID_SUCCESS,
    GET_CELL_BY_ID_FAILED,
    ADD_CELL_REQUESTED,
    ADD_CELL_SUCCESS,
    ADD_CELL_FAILED,
    UPDATE_CELL_REQUESTED,
    UPDATE_CELL_SUCCESS,
    UPDATE_CELL_FAILED,
    DELETE_CELL_REQUESTED,
    DELETE_CELL_SUCCESS,
    DELETE_CELL_FAILED,
} from '../actions/types';

import {
    getCellsRequested,
    getCellsSuccess,
    getCellsFailed,
    getCellByIdRequested,
    getCellByIdSuccess,
    getCellByIdFailed,
    addCellRequested,
    addCellSuccess,
    addCellFailed,
    updateCellRequested,
    updateCellSuccess,
    updateCellFailed,
    deleteCellRequested,
    deleteCellSuccess,
    deleteCellFailed,
    getCellStatsFailed,
    getCellStatsSuccess,
    getCellUpcomingBirthdaysSuccess,
    getCellUpcomingBirthdaysFailed,
} from '../actions/cellAction';

import API from '../utils/api';
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
/** ----- CELLS ----- */
export function* cells() {
    try {
        const response = yield call(API.getData, 'cells');
        console.log('response', response);
        yield put(getCellsSuccess(response));
    } catch (error) {
        yield put(getCellsFailed(error));
    }
}

export function* getCell(id) {
    try {
        const response = yield call(API.getData, `cells/${id}`);
        console.log('response', response);
        yield put(getCellByIdSuccess(response));
    } catch (error) {
        yield put(getCellByIdFailed(error));
    }
}

export function* addCell(action) {
    try {
        const response = yield call(API.postData, 'cells', action.payload);
        console.log('response', response);
        yield put(addCellSuccess(response));
    } catch (error) {
        yield put(addCellFailed(error));
    }
}

export function* updateCell(action) {
    try {
        const response = yield call(API.putData, `cells/${action.payload.id}`, action.payload);
        console.log('response', response);
        yield put(updateCellSuccess(response));
    } catch (error) {
        yield put(updateCellFailed(error));
    }
}

export function* deleteCell({id}) {
    try {
        const response = yield call(API.deleteData, `cells/${id}`);
        console.log('response', response);
        yield put(deleteCellSuccess({...response, id: id}));
    } catch (error) {
        yield put(deleteCellFailed(error));
    }
}

export function* getCellStats({id}) {
    try {
        const response = yield call(API.getData, `cells/${id}/totals`);
        console.log('response', response);
        yield put(getCellStatsSuccess(response));
    } catch (error) {
        yield put(getCellStatsFailed(error));
    }
}

export function* getCellBirthdays({id}) {
    try {
        const response = yield call(API.getData, `cells/${id}/upcomingBirthdays`);
        console.log('response', response);
        yield put(getCellUpcomingBirthdaysSuccess(response));
    } catch (error) {
        yield put(getCellUpcomingBirthdaysFailed(error));
    }
}


export default function* cellSaga() {
    yield takeLatest(GET_CELLS_REQUESTED, cells);
    yield takeLatest(GET_CELL_BY_ID_REQUESTED, getCell);
    yield takeLatest(ADD_CELL_REQUESTED, addCell);
    yield takeLatest(UPDATE_CELL_REQUESTED, updateCell);
    yield takeLatest(DELETE_CELL_REQUESTED, deleteCell);
    yield takeLatest('GET_CELL_STATS_REQUESTED', getCellStats);
    yield takeLatest('GET_CELL_UPCOMING_BIRTHDAYS_REQUESTED', getCellBirthdays);
}