import {
    GET_ADMIN_REPORT_REQUESTED,
    GET_REPORTS_REQUESTED,
    GET_REPORT_BY_ID_REQUESTED,
    GET_REPORT_DETAILS_REQUESTED,
    ADD_REPORT_REQUESTED,
    UPDATE_REPORT_REQUESTED,
    DELETE_REPORT_REQUESTED,
    SUBMIT_NEW_REPORT_REQUESTED,
    CLEAR_REPORTS,
} from '../actions/types';

import {
    getReportByIdRequested,
    getReportByIdSuccess,
    getReportByIdFailed,
    getReportsRequested,
    getReportsSuccess,
    getReportsFailed,
    // generateReportRequested,
    // generateReportSuccess,
    // generateReportFailed,
    getAdminReportSuccess,
    getAdminReportFailed,
    getReportDetailsRequested,
    getReportDetailsSuccess,
    getReportDetailsFailed,
    addReportRequested,
    addReportSuccess,
    addReportFailed,
    updateReportRequested,
    updateReportSuccess,
    updateReportFailed,
    deleteReportRequested,
    deleteReportSuccess,
    deleteReportFailed,
    submitNewReportRequested,
    submitNewReportSuccess,
    submitNewReportFailed,
    clearReports,
} from '../actions/reportAction';

import API from '../utils/api';
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

/** ----- REPORT ----- */
export function* getAdminReport({ payload }) {
    try {
        const response = yield call(API.postData, 'report/admin', payload);
        console.log('response', response);
        yield put(getAdminReportSuccess(response));
    } catch (error) {
        yield put(getAdminReportFailed(error));
    }
}

export function* getReport({ payload }) {
    try {
        const response = yield call(API.getData, `report/${payload}`);
        console.log('response', response);
        yield put(getReportsSuccess(response));
    } catch (error) {
        yield put(getReportsFailed(error));
    }
}

export function* getReportDetails({ payload }) {
    try {
        const response = yield call(API.getData, `cells/${payload.cellId}/details`);
        console.log('response', response);
        let { cellMember, Guest } = response;
        Guest = Guest.map(guest => {
            return {
                ...guest,
                attendance: false,
                irregular: false,
            }
        });

        cellMember = cellMember.map(member => {
            return {
                ...member,
                attendance: false,
                irregular: false,
            }
        });
        const testimonies = "";
        const evaluations = "";
        const modifiedResponse = { ...response, cellMember, Guest, testimonies, evaluations };
        yield put(getReportDetailsSuccess(modifiedResponse));
    } catch (error) {
        yield put(getReportDetailsFailed(error));
    }
}

export function* getReportById({ cellId, id }) {
    try {
        const response = yield call(API.getData, `report/${cellId}/${id}`);
        console.log('response', response);
        yield put(getReportByIdSuccess(response));
    } catch (error) {
        yield put(getReportByIdFailed(error));
    }
}

export function* addReport({ payload }) {
    try {
        const response = yield call(API.postData, 'report', payload);
        console.log('response', response);
        yield put(addReportSuccess(response));
    } catch (error) {
        yield put(addReportFailed(error));
    }
}

export function* submitNewReport({ payload }) {
    try {
        const response = yield call(API.postData, 'report', payload);
        console.log('response', response);
        yield put(submitNewReportSuccess(response));
    } catch (error) {
        yield put(submitNewReportFailed(error));
    }
}

export function* updateReport({ payload }) {
    try {
        const response = yield call(API.putData, `report/${payload.id}`, payload);
        console.log('response', response);
        yield put(updateReportSuccess(response));
    } catch (error) {
        yield put(updateReportFailed(error));
    }
}

export function* deleteReport({ payload }) {
    try {
        const response = yield call(API.deleteData, `report/${payload.id}`);
        console.log('response', response);
        yield put(deleteReportSuccess({...response, id: payload.id}));
    } catch (error) {
        yield put(deleteReportFailed(error));
    }
}

// export function* generateReport({payload}) {
//     try {
//         const response = yield call(API.postData, 'reports/admin', payload);
//         console.log('response', response);
//         yield put(generateReportSuccess(response));
//     } catch (error) {
//         yield put(generateReportFailed(error));
//     }
// }

export default function* reportSaga() {
    yield takeLatest(GET_ADMIN_REPORT_REQUESTED, getAdminReport);
    yield takeEvery(GET_REPORTS_REQUESTED, getReport);
    yield takeLatest(GET_REPORT_BY_ID_REQUESTED, getReportById);
    yield takeLatest(ADD_REPORT_REQUESTED, addReport);
    yield takeLatest(UPDATE_REPORT_REQUESTED, updateReport);
    yield takeLatest(DELETE_REPORT_REQUESTED, deleteReport);
    yield takeLatest(GET_REPORT_DETAILS_REQUESTED, getReportDetails);
    yield takeLatest(SUBMIT_NEW_REPORT_REQUESTED, submitNewReport);
}


