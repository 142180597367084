import {
    GET_USER_REQUESTED,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    UPDATE_USER_REQUESTED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_PASSWORD_REQUESTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,
} from '../actions/types';

const initialState = {
    user: null,
    error: null,
    loading: false,
    success: false,
    userGetRequested: false,
    userGetSuccess: false,
    userGetFailed: false,
    userUpdateRequested: false,
    userUpdateSuccess: false,
    userUpdateFailed: false,
    passwordUpdateRequested: false,
    passwordUpdateSuccess: false,
    passwordUpdateFailed: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_REQUESTED:
            return {
                ...state,
                loading: true,
                userGetRequested: true,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                userGetRequested: false,
                userGetSuccess: true,
                user: action.payload,
            };

        case GET_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_USER_REQUESTED:
            return {
                ...state,
                loading: true,
                userUpdateRequested: true,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                userUpdateRequested: false,
                userUpdateSuccess: true,
                user: action.payload,
            };

        case UPDATE_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPDATE_PASSWORD_REQUESTED:
            return {
                ...state,
                loading: true,
                passwordUpdateRequested: true,
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                passwordUpdateRequested: false,
                passwordUpdateSuccess: true,
            };

        case UPDATE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}