import React, { useEffect, useState } from "react";
import Table, { ActionsPill } from '../../components/Table'  // new
import { getReportsRequested, getAdminReportRequested } from "../../actions/reportAction";
import { DocumentDownloadIcon, DocumentIcon, DocumentReportIcon } from "@heroicons/react/outline";
// import Modal from '../../components/Modal';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Logo } from '../../utils/images';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AdminReport = ({
    user,
    report,
    adminReportGetRequested,
    getAdminReportRequested: getReport
}) => {
    // const [showModal, setShowModal] = React.useState(false);
    const [errors, setErrors] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [formData, setFormData] = React.useState({});
    const [genPDF, setGenPDF] = React.useState(false);
    const [formIsValid, setFormIsValid] = React.useState(false);
    const base64Img = Logo();
    let totalPagesExp = "{total_pages_count_string}";
    const columns = React.useMemo(() => [
        {
            Header: "No.",
            accessor: 'id',
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-2">
                    <p>{cell.row.index + 1}</p>
                </div>
            ),
        },
        {
            Header: "Leader",
            accessor: 'cell.CellLeader.name',
        },
        {
            Header: "Cell Meeting Day",
            accessor: 'cell.meeting_day',
        },
        {
            Header: "Cell Type",
            accessor: "cell.cell_type",
        },
        {
            Header: "Total Regular Members",
            accessor: 'cellTotal',
        },
        {
            Header: "Members Present",
            accessor: 'attendanceTotal',
        },
        {
            Header: "Total Present",
            accessor: 'totalAttendance',
        },
        {
            Header: "Remarks ",
            accessor: 'remarks',
            // accessor: 'status',
            Cell: ({ cell, row }) => (
                <div className="flex items-center space-x-2">
                    {/* <p className="text-xs">No.of guests:
                        {row.original.guestsTotal || 0}</p> */}
                </div>
            ),
            // Cell: ActionsPill,
        },
    ], []);

    React.useEffect(() => {
        const isValid = handleValidation();
        setFormIsValid(isValid);
    }, [formData]);

    useEffect(() => {
        if (genPDF && report && report.length > 0 && formData && columns && totalPagesExp) {
            setGenPDF(false);
            console.log("Generate PDF");
            //Generate PDF
            const doc = new jsPDF("p", "pt");

            // Define table columns and rows
            const tableColumns = columns.map(column => column.Header);
            const tableData = report.map((row, index) => {
                const rowData = [];
                columns.forEach(column => {
                    if (column.accessor === 'cell.CellLeader.name') {
                        rowData.push(row.cell.CellLeader?.name || '');
                    } else if (column.accessor === 'cell.meeting_day') {
                        rowData.push(row.cell?.meeting_day || '')
                    } else if (column.accessor === 'cell.cell_type') {
                        rowData.push(row.cell?.cell_type || '')
                    } else if (column.accessor === 'remarks') {
                        // rowData.push(`No.of guests: ${row.guestsTotal || 0}`)
                    } else if (column.accessor === 'id') {
                        rowData.push(index + 1);
                    } else {
                        rowData.push(row[column.accessor]);
                    }
                });
                return rowData;
            });


            // Auto-generate table
            doc.autoTable({
                head: [tableColumns],
                body: tableData,
                didDrawPage: function (data) {
                    const imgWidth = 30;
                    const imgHeight = 30;
                    const title = `Cell Report - ${formData.startDate} - ${formData.endDate}`;
                    const titleFontSize = 16;
                    const titleX = data.settings.margin.left + (imgWidth + 10);
                    const titleY = 22;

                    doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 1, imgWidth, imgHeight);
                    doc.setFontSize(titleFontSize);
                    doc.text(title, titleX, titleY);

                    // Add church title
                    const churchTitle = "ANFGC Halifax";
                    const churchTitleFontSize = 16;
                    const churchTitleX = data.settings.margin.left + (imgWidth + 10);
                    const churchTitleY = 40;

                    doc.setFontSize(churchTitleFontSize);
                    doc.text(churchTitle, churchTitleX, churchTitleY);

                    // Footer
                    var str = "Page " + doc.internal.getNumberOfPages()
                    // Total page number plugin only available in jspdf v1.0+
                    if (typeof doc.putTotalPages === 'function') {
                        str = str + " of " + totalPagesExp;
                    }
                },
                styles: {
                    halign: 'center',
                },
                margin: { top: 65 }
            });

            // Save or download the PDF
            doc.save(`cell_report_${formData.startDate}_${formData.endDate}.pdf`);
        }
    }, [genPDF, report, formData, base64Img, columns, totalPagesExp]);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (formData != null
            && (formData.startDate == null || formData.startDate === "")) {
            formIsValid = false;
            errors["date"] = "Start Date cannot be empty";
        }
        if (formData != null
            && (formData.endDate == null || formData.endDate === "")) {
            formIsValid = false;
            errors["date"] = "End Date cannot be empty";
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleCreateReport = () => {
        console.log("Generate member");
        getReport(formData);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value ? moment(value).format("YYYY-MM-DD") : "" }));
    }

    const generatePDF = () => {
        console.log("clicked Generate PDF");
        getReport(formData);
        setGenPDF(true);
    }



    return (
        <div className="h-400 bg-gray-100 text-gray-900">
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-between content-between">
                    <h1 className="text-xl font-semibold">Cell Reports</h1>
                </div>
                <div className="flex flex-col items-start space-y-2 md:flex-row md:items-end md:space-y-0 place-content-start mt-6 mb-6">
                    <div className="flex flex-col w-full md:w-1/5 md:pr-6">
                        <label htmlFor="day" className="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                        <div className="mt-2">
                            <input className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                id="startDate"
                                name="startDate"
                                type="date"
                                required
                                value={formData?.startDate || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:w-1/5 md:pr-6">
                        <label htmlFor="day" className="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                        <div className="mt-2">
                            <input className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                id="endDate"
                                name="endDate"
                                type="date"
                                required
                                value={formData?.endDate || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <Button disabled={!formIsValid} handleSubmit={handleCreateReport} name="Get Report" icon={<DocumentReportIcon className="h-4 w-4" />} />
                        <Button disabled={!formIsValid} handleSubmit={generatePDF} name="Download PDF" pdf={true} icon={<DocumentDownloadIcon className="h-4 w-4" />} />

                    </div>
                    {/* <button
                        onClick={() => handleCreateReport()}
                        disabled={!formIsValid}
                        className="max-h-8 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        <i className="fas fa-plus"></i>
                        Generate Report
                    </button>
                    <button
                        onClick={() => generatePDF()}
                        disabled={!formIsValid}
                        className="max-h-8 text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        <i className="fas fa-plus"></i>
                        Generate PDF
                    </button> */}
                </div>
                <div className="mt-6 overflow-x-auto">
                    {report && <Table columns={columns} data={report} />}
                    {/* <Table columns={columns} data={data} /> */}
                </div>
            </main>
        </div>
    );
}

const Button = ({ disabled, handleSubmit, name, icon, pdf }) => {
    if (disabled) {
        return (
            <button
                disabled={true}
                className={`${pdf ? 'text-red-500 border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600' : 'text-emerald-500 border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600'} max-h-8 text-emerald-500 border border-emerald-500 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-25 cursor-not-allowed flex flex-row items-center`} type="button"
            >
                {icon} {name}
            </button>
        )
    }
    return (
        <button
            disabled={false}
            onClick={handleSubmit}
            className={`${pdf ? 'text-red-500 border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600' : 'text-emerald-500 border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600'} max-h-8  border  font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 flex flex-row items-center`} type="button"
        >
            {icon} {name}
        </button>
    )
}

AdminReport.propTypes = {
    report: PropTypes.array,
    reportsGetRequested: PropTypes.bool,
    adminReportGetSuccess: PropTypes.bool,
    getReportsRequested: PropTypes.func,
    addReportRequested: PropTypes.func,
};

AdminReport.defaultProps = {
    report: [],
    reportsGetRequested: false,
    adminReportGetSuccess: false,
    reportGetRequested: false,
};

const mapStateToProps = (state) => ({
    report: state.report.reports,
    adminReportGetSuccess: state.report.adminReportGetSuccess,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getReportsRequested,
    getAdminReportRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdminReport);