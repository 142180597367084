import React, { useMemo, useEffect, useState } from 'react'
import Table from './../../components/Table'  // new
import { connect } from 'react-redux'
import { getCellLeadersRequested, updateCellLeaderRequested, addCellLeaderRequested, deleteCellLeaderRequested } from '../../actions/cellLeaderAction'
// import { GetCellLeaders, AddCellLeader, EditCellLeader } from '../../reducers/cellReducer';
import Modal from '../../components/Modal';
import { TrashIcon } from '@heroicons/react/outline'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

const Users = ({
    cellLeaderGetRequested,
    cellLeader,
    cellLeaders,
    getCellLeadersRequested: getCellLeaders,
    updateCellLeaderRequested: updateCellLeader,
    deleteCellLeaderRequested: deleteLeader,
    addCellLeaderRequested: addCellLeader
}) => {
    // const [cellLeaders, setCellLeaders] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    // const [error, setError] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    // const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        console.log("Cell Leaders", cellLeaders);
        getCellLeaders()
    }, []);

    const columns = useMemo(() => [
        {
            Header: "#",
            Cell: ({ cell }) => (
                <div className="flex items-center space-x-2">
                    <p>{cell.row.index + 1}</p>
                </div>
            ),
        },
        {
            Header: "Name",
            accessor: 'name',
        },
        {
            Header: "Email",
            accessor: 'email',
        },
        {
            Header: "Username",
            accessor: 'username',
        },
        {
            Header: "Action",
            accessor: 'id',
            idAccessor: 'id',
            Cell: ({ cell, row, column }) => (
                <div className="flex items-center space-x-1">
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-yellow-100 text-yellow-800"
                        onClick={() => handleEdit(cell.row.original)}
                    >
                        Edit
                    </button>
                    <button
                        className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm bg-red-100 text-red-800"
                        onClick={() => handleDeleteUser(row.original[column.idAccessor])}
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </div>
            ),
        },
    ], []);

    const handleEdit = (data) => {
        console.log(data);
        setEdit(true);
        setFormData(data);
        setShowModal(true);
    };

    const handleDeleteUser = (id) => {
        console.log("Delete Report", id);
        deleteLeader(id);
    };

    const handleAddCellLeader = () => {
        console.log("Add cell leader");
        addCellLeader(formData);
        setShowModal(false);
    }

    const handleOpenModal = () => {
        console.log("Add cell leader");
        setShowModal(true);
    };

    const handleCloseModal = (e) => {
        e.preventDefault();
        setFormData({});
        setShowModal(false);
        setEdit(false);
    };

    const handleEditLeader = (e) => {
        e.preventDefault();
        console.log("Edit Cell Leader");
        console.log(formData);
        updateCellLeader(formData);
        // setCellLeaders([...cellLeaders]);
        setFormData({});
        setShowModal(false);
        setEdit(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(values => ({ ...values, [name]: value }))
    }

    // const data = React.useMemo(() => cellLeaders, [])

    return (
        <div className="h-400 bg-gray-100 text-gray-900">
            <Modal title={edit ? "Edit cell leader" : "Add cell leader"} onClose={handleCloseModal} show={showModal} data={formData} onSubmit={edit && edit ? handleEditLeader : handleAddCellLeader}>
                <form className='space-y-6'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="cell name"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.name || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="enter email"
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.email || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">Username</label>
                        <div className="mt-2">
                            <input
                                id="username"
                                name="username"
                                type="text"
                                autoComplete='false'
                                required
                                className="px-2 py-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                value={formData?.username || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                </form>
            </Modal>
            <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="flex flex-row justify-between content-between">
                    <h1 className="text-xl font-semibold">Cell Leaders</h1>
                    <button
                        onClick={() => handleOpenModal()}
                        className="text-emerald-500 border border-emerald-500 hover:bg-emerald-500 hover:text-white active:bg-emerald-600 font-bold uppercase text-sm px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                    >
                        <i className="fas fa-plus"></i>
                        Add Cell Leader
                    </button>
                </div>
                <div className="mt-6">
                    {cellLeaders.length > 0 && (<Table columns={columns} data={cellLeaders} />)}
                    {cellLeaders.length === 0 && (
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-xl font-semibold">No cell leaders found</h1>
                            <p className="text-sm text-gray-500">Add a new cell leader</p>
                        </div>
                    )}
                    {/* <Table columns={columns} data={data} /> */}
                </div>
            </main>
        </div>
    );
}

Users.propTypes = {
    error: PropTypes.string,
    cellLeader: PropTypes.object,
    cellLeaders: PropTypes.array,
    getCellLeadersRequested: PropTypes.func.isRequired,
    cellLeaderGetRequested: PropTypes.bool.isRequired,
};

Users.defaultProps = {
    error: '',
    cellLeaders: [],
    cellLeaderGetRequested: false,
};

const mapStateToProps = (state) => ({
    error: state.auth.loginError,
    cellLeader: state.cellLeader.cellLeader,
    cellLeaders: state.cellLeader.cellLeaders
});

const mapDispatchToProps = dispatch => bindActionCreators({
    getCellLeadersRequested,
    updateCellLeaderRequested,
    addCellLeaderRequested,
    deleteCellLeaderRequested,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);