import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import rootSaga from "../sagas";

export default function configureRootStore() {
    const sagaMiddleware = createSagaMiddleware();
    // const store = createStore(
    //     rootReducer,
    //     compose(
    //         applyMiddleware(sagaMiddleware)
    //     )
    // );
    const store = configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware]
    });
    sagaMiddleware.run(rootSaga);
    return store;
}

// const sagaMiddleware = createSagaMiddleware();
// sagaMiddleware.run(rootSaga);
// export const store = configureStore({
//     reducer: rootReducer,
//     middleware: [sagaMiddleware]
// });

