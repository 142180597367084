import axios from 'axios';
const baseUrl = 'https://cellapi.anfgchalifax.com/api/v1/';
// const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/v1/': 'https://walrus-app-552s4.ondigitalocean.app/api/v1/';
// const baseUrl = 'http://localhost:3000/api/v1/';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }
);

const redirectToLoginIfUnauthenticated = (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        // window.location.href = '/login';
        window.location.assign('/login');
        console.log('error', error, error.response);
    }
    return error.response;
};

// const redirectToLoginIfLogout = response => {
//     window.location.assign('/login');
//     // return response;
// };

const login = (url, body) => {
    return axios.post(`${baseUrl}${url}`, body).then(response => response.data).catch( error => {
        console.log('error', error);
        return error.response;
    });
};

const postData = (url, body) => {
    return axios.post(`${baseUrl}${url}`, body).then(response => response.data).catch(redirectToLoginIfUnauthenticated);
};

const getData = (url) => {
    return axios.get(`${baseUrl}${url}`).then(response => response.data).catch(redirectToLoginIfUnauthenticated);
}

const putData = (url, body) => {
    return axios.put(`${baseUrl}${url}`, body).then(response => response.data).catch(redirectToLoginIfUnauthenticated);
}

const deleteData = (url) => {
    return axios.delete(`${baseUrl}${url}`).then(response => response.data).catch(redirectToLoginIfUnauthenticated);
}
const API = {
    login,
    postData,
    getData,
    putData,
    deleteData,
}
export default API;
