import {
    GET_ADMIN_REPORT_REQUESTED,
    GET_ADMIN_REPORT_SUCCESS,
    GET_ADMIN_REPORT_FAILED,
    GET_REPORT_BY_ID_REQUESTED,
    GET_REPORT_BY_ID_SUCCESS,
    GET_REPORT_BY_ID_FAILED,
    GET_REPORTS_REQUESTED,
    GET_REPORTS_SUCCESS,
    GET_REPORTS_FAILED,
    // GENERATE_REPORT_REQUESTED,
    // GENERATE_REPORT_SUCCESS,
    // GENERATE_REPORT_FAILED,
    GET_REPORT_DETAILS_REQUESTED,
    GET_REPORT_DETAILS_SUCCESS,
    GET_REPORT_DETAILS_FAILED,
    ADD_REPORT_REQUESTED,
    ADD_REPORT_SUCCESS,
    ADD_REPORT_FAILED,
    UPDATE_REPORT_REQUESTED,
    UPDATE_REPORT_SUCCESS,
    UPDATE_REPORT_FAILED,
    DELETE_REPORT_REQUESTED,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_FAILED,
    UPDATE_ATTENDANCE,
    UPDATE_GUEST_ATTENDANCE,
    UPDATE_TESTIMONIES,
    UPDATE_EVALUATIONS,
    UPDATE_DATE,
    CLEAR_REPORTS,
    SUBMIT_NEW_REPORT_REQUESTED,
    SUBMIT_NEW_REPORT_SUCCESS,
    SUBMIT_NEW_REPORT_FAILED,
} from '../actions/types';

/** ----- REPORT ----- */
export const getAdminReportRequested = (payload) => ({ type: GET_ADMIN_REPORT_REQUESTED, payload });
export const getAdminReportSuccess = (payload) => ({ type: GET_ADMIN_REPORT_SUCCESS, payload });
export const getAdminReportFailed = (payload) => ({ type: GET_ADMIN_REPORT_FAILED, payload });

export const getReportsRequested = (id) => ({ type: GET_REPORTS_REQUESTED, payload: id });
export const getReportsSuccess = (payload) => ({ type: GET_REPORTS_SUCCESS, payload });
export const getReportsFailed = (payload) => ({ type: GET_REPORTS_FAILED, payload });

export const getReportByIdRequested = ({ id, cellId }) => ({ type: GET_REPORT_BY_ID_REQUESTED, cellId, id });
export const getReportByIdSuccess = (payload) => ({ type: GET_REPORT_BY_ID_SUCCESS, payload });
export const getReportByIdFailed = (payload) => ({ type: GET_REPORT_BY_ID_FAILED, payload });

// export const generateReportRequested = (payload) => ({ type: GENERATE_REPORT_REQUESTED, payload });
// export const generateReportSuccess = (payload) => ({ type: GENERATE_REPORT_SUCCESS, payload });
// export const generateReportFailed = (payload) => ({ type: GENERATE_REPORT_FAILED, payload });

export const getReportDetailsRequested = (payload) => ({ type: GET_REPORT_DETAILS_REQUESTED, payload });
export const getReportDetailsSuccess = (payload) => ({ type: GET_REPORT_DETAILS_SUCCESS, payload });
export const getReportDetailsFailed = (payload) => ({ type: GET_REPORT_DETAILS_FAILED, payload });

export const addReportRequested = (payload) => ({ type: ADD_REPORT_REQUESTED, payload });
export const addReportSuccess = (payload) => ({ type: ADD_REPORT_SUCCESS, payload });
export const addReportFailed = (payload) => ({ type: ADD_REPORT_FAILED, payload });

export const updateReportRequested = (payload) => ({ type: UPDATE_REPORT_REQUESTED, payload });
export const updateReportSuccess = (payload) => ({ type: UPDATE_REPORT_SUCCESS, payload });
export const updateReportFailed = (payload) => ({ type: UPDATE_REPORT_FAILED, payload });

export const deleteReportRequested = (payload) => ({ type: DELETE_REPORT_REQUESTED, payload });
export const deleteReportSuccess = (payload) => ({ type: DELETE_REPORT_SUCCESS, payload });
export const deleteReportFailed = (payload) => ({ type: DELETE_REPORT_FAILED, payload });

export const clearReports = () => ({ type: CLEAR_REPORTS });

export const updateAttendance = (payload) => ({ type: UPDATE_ATTENDANCE, payload });
export const updateGuestAttendance = (payload) => ({ type: UPDATE_GUEST_ATTENDANCE, payload });
export const updateTestimonies = (payload) => ({ type: UPDATE_TESTIMONIES, payload });
export const updateEvaluations = (payload) => ({ type: UPDATE_EVALUATIONS, payload });
export const updateDate = (payload) => ({ type: UPDATE_DATE, payload });

export const submitNewReportRequested = (payload) => ({ type: SUBMIT_NEW_REPORT_REQUESTED, payload });
export const submitNewReportSuccess = (payload) => ({ type: SUBMIT_NEW_REPORT_SUCCESS, payload });
export const submitNewReportFailed = (payload) => ({ type: SUBMIT_NEW_REPORT_FAILED, payload });

