import API from '../utils/api';
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    GET_CELL_LEADER_BY_ID_REQUESTED,
    GET_CELL_LEADER_BY_ID_SUCCESS,
    GET_CELL_LEADER_BY_ID_FAILED,
    GET_CELL_LEADERS_REQUESTED,
    GET_CELL_LEADERS_SUCCESS,
    GET_CELL_LEADERS_FAILED,
    ADD_CELL_LEADER_REQUESTED,
    ADD_CELL_LEADER_SUCCESS,
    ADD_CELL_LEADER_FAILED,
    UPDATE_CELL_LEADER_REQUESTED,
    UPDATE_CELL_LEADER_SUCCESS,
    UPDATE_CELL_LEADER_FAILED,
    DELETE_CELL_LEADER_REQUESTED,
    DELETE_CELL_LEADER_SUCCESS,
    DELETE_CELL_LEADER_FAILED,
} from '../actions/types';

import {
    getCellLeadersRequested,
    getCellLeadersSuccess,
    getCellLeadersFailed,
    getCellLeaderByIdRequested,
    getCellLeaderByIdSuccess,
    getCellLeaderByIdFailed,
    addCellLeaderRequested,
    addCellLeaderSuccess,
    addCellLeaderFailed,
    updateCellLeaderRequested,
    updateCellLeaderSuccess,
    updateCellLeaderFailed,
    deleteCellLeaderRequested,
    deleteCellLeaderSuccess,
    deleteCellLeaderFailed,
} from '../actions/cellLeaderAction';

/* ----- CELL LEADERS ----- */
export function* getCellLeaders() {
    try {
        const response = yield call(API.getData, 'users/role/leaders');
        // console.log('response', response);
        yield put(getCellLeadersSuccess(response));
    } catch (error) {
        yield put(getCellLeadersFailed(error));
    }
}

export function* getCellLeaderById(id) {
    try {
        const response = yield call(API.getData, `users/${id}`);
        // console.log('response', response);
        yield put(getCellLeaderByIdSuccess(response));
    } catch (error) {
        yield put(getCellLeaderByIdFailed(error));
    }
}

export function* addCellLeader({payload}) {
    try {
        const response = yield call(API.postData, 'users', payload);
        // console.log('response', response);
        yield put(addCellLeaderSuccess(response));
    } catch (error) {
        yield put(addCellLeaderFailed(error));
    }
}

export function* updateCellLeader({payload}) {
    try {
        const response = yield call(API.putData, `users/${payload.id}/leader`, payload);
        // console.log('response', response);
        yield put(updateCellLeaderSuccess(response));
    } catch (error) {
        yield put(updateCellLeaderFailed(error));
    }
}

export function* deleteCellLeader({payload}) {
    try {
        const response = yield call(API.deleteData, `users/${payload}`);
        // console.log('response', response);
        yield put(deleteCellLeaderSuccess({...response, id: payload}));
    } catch (error) {
        yield put(deleteCellLeaderFailed(error));
    }
}

export default function* cellLeadersSaga() {
    yield takeEvery(GET_CELL_LEADERS_REQUESTED, getCellLeaders);
    yield takeEvery(GET_CELL_LEADER_BY_ID_REQUESTED, getCellLeaderById);
    yield takeEvery(ADD_CELL_LEADER_REQUESTED, addCellLeader);
    yield takeEvery(UPDATE_CELL_LEADER_REQUESTED, updateCellLeader);
    yield takeEvery(DELETE_CELL_LEADER_REQUESTED, deleteCellLeader);
}


