import {
    GET_CELL_LEADERS_REQUESTED,
    GET_CELL_LEADERS_SUCCESS,
    GET_CELL_LEADERS_FAILED,
    GET_CELL_LEADER_BY_ID_REQUESTED,
    GET_CELL_LEADER_BY_ID_SUCCESS,
    GET_CELL_LEADER_BY_ID_FAILED,
    ADD_CELL_LEADER_REQUESTED,
    ADD_CELL_LEADER_SUCCESS,
    ADD_CELL_LEADER_FAILED,
    UPDATE_CELL_LEADER_REQUESTED,
    UPDATE_CELL_LEADER_SUCCESS,
    UPDATE_CELL_LEADER_FAILED,
    DELETE_CELL_LEADER_REQUESTED,
    DELETE_CELL_LEADER_SUCCESS,
    DELETE_CELL_LEADER_FAILED,
} from './types';

/* ----- CELL LEADERS ----- */
export const getCellLeadersRequested = () => ({ type: GET_CELL_LEADERS_REQUESTED });
export const getCellLeadersSuccess = (data) => ({ type: GET_CELL_LEADERS_SUCCESS, payload: data });
export const getCellLeadersFailed = (data) => ({ type: GET_CELL_LEADERS_FAILED, payload: data });

export const getCellLeaderByIdRequested = (id) => ({ type: GET_CELL_LEADER_BY_ID_REQUESTED, payload: id });
export const getCellLeaderByIdSuccess = (data) => ({ type: GET_CELL_LEADER_BY_ID_SUCCESS, payload: data });
export const getCellLeaderByIdFailed = (data) => ({ type: GET_CELL_LEADER_BY_ID_FAILED, payload: data });

export const addCellLeaderRequested = (data) => ({ type: ADD_CELL_LEADER_REQUESTED, payload: data });
export const addCellLeaderSuccess = (data) => ({ type: ADD_CELL_LEADER_SUCCESS, payload: data });
export const addCellLeaderFailed = (data) => ({ type: ADD_CELL_LEADER_FAILED, payload: data });

export const updateCellLeaderRequested = (data) => ({ type: UPDATE_CELL_LEADER_REQUESTED, payload: data });
export const updateCellLeaderSuccess = (data) => ({ type: UPDATE_CELL_LEADER_SUCCESS, payload: data });
export const updateCellLeaderFailed = (data) => ({ type: UPDATE_CELL_LEADER_FAILED, payload: data });

export const deleteCellLeaderRequested = (id) => ({ type: DELETE_CELL_LEADER_REQUESTED, payload: id });
export const deleteCellLeaderSuccess = (data) => ({ type: DELETE_CELL_LEADER_SUCCESS, payload: data });
export const deleteCellLeaderFailed = (data) => ({ type: DELETE_CELL_LEADER_FAILED, payload: data });

