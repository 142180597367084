import {
    GET_USER_REQUESTED,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    UPDATE_USER_REQUESTED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_PASSWORD_REQUESTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,
} from './types';
export const getUserRequested = ({id}) => ({ type: GET_USER_REQUESTED, id: id });
export const getUserSuccess = (data) => ({ type: GET_USER_SUCCESS, payload: data });
export const getUserFailed = (data) => ({ type: GET_USER_FAILED, payload: data });

export const updateUserRequested = (data) => ({ type: UPDATE_USER_REQUESTED, payload: data });
export const updateUserSuccess = (data) => ({ type: UPDATE_USER_SUCCESS, payload: data });
export const updateUserFailed = (data) => ({ type: UPDATE_USER_FAILED, payload: data });

export const updatePasswordRequested = (data) => ({ type: UPDATE_PASSWORD_REQUESTED, payload: data });
export const updatePasswordSuccess = (data) => ({ type: UPDATE_PASSWORD_SUCCESS, payload: data });
export const updatePasswordFailed = (data) => ({ type: UPDATE_PASSWORD_FAILED, payload: data });
