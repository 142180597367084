import API from '../utils/api';
import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    ON_LOAD_LOGIN_REQUESTED,
    ON_LOAD_LOGIN_SUCCESS,
    ON_LOAD_LOGIN_FAILED,
    LOGOUT_REQUESTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    POST_RESET_PASSWORD_REQUESTED,
    POST_RESET_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAILED,
} from '../actions/types';

import {
    onLoginSuccess,
    onLoginFailed,
    onLogoutSuccess,
    onLogoutFailed,
    onResetPasswordSuccess,
    onResetPasswordFailed,
} from '../actions/authAction';

/* ----- LOGIN ----- */
export function* onLoadLogin(action) {
    try {
        const response = yield call(API.login, 'auth/login', action.payload);
        console.log('response', response);
        if (response.status === 200 || response.token) {
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', JSON.stringify(response));
            yield put(onLoginSuccess(response));
        } else {
            yield put(onLoginFailed("username or password is incorrect"));
        }
    } catch (error) {
        yield put(onLoginFailed(error));
    }
}

export function* watchOnLoadLogin() {
    yield takeLatest(ON_LOAD_LOGIN_REQUESTED, onLoadLogin);
}

/* ----- LOGOUT ----- */
export function* onLogout() {
    try {
        let data = JSON.parse(localStorage.getItem('user'));
        delete data.token;
        yield call(API.postData, 'auth/logout', data);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        yield put(onLogoutSuccess());
    } catch (error) {
        yield put(onLogoutFailed(error.response));
    }
}

export function* watchOnLogout() {
    yield takeLatest(LOGOUT_REQUESTED, onLogout);
}

/* ----- RESET PASSWORD ----- */
export function* onResetPassword(action) {
    try {
        const response = yield call(API.resetPassword, action.payload);
        yield put(onResetPasswordSuccess(response.data));
    } catch (error) {
        yield put(onResetPasswordFailed(error.response));
    }
}

export default function* authSaga() {
    yield takeEvery(ON_LOAD_LOGIN_REQUESTED, onLoadLogin);
    yield takeEvery(LOGOUT_REQUESTED, onLogout);
    yield takeEvery(POST_RESET_PASSWORD_REQUESTED, onResetPassword);
}